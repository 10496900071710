import { Component, OnInit } from "@angular/core";
import { Article } from "../../entities/Article";
import { ActivatedRoute } from "@angular/router";
import { NavController } from "@ionic/angular";
import { API_URL } from "../../../environments/environment";
import { AuthService } from "../../services/auth/auth.service";
import { NewsService } from "../../services/news/news.service";
import { AlertService } from "../../services/alert/alert.service";
import { TranslateService } from "@ngx-translate/core";
import moment from "moment";
import { HelpersModule } from "../../modules/helpers/helpers.module";
import { BrowserService } from "../../services/browser/browser.service";

@Component({
    selector: "app-news-detail",
    templateUrl: "./news-detail.page.html",
    styleUrls: ["./news-detail.page.scss"]
})
export class NewsDetailPage implements OnInit {
    private placeholderImageUrl = "../assets/images/default-account.png";
    public article = new Article(null);
    public isDataLoaded = false;
    public articles = [];
    public author = null;
    public coloredToolbar = false;
    public toolbarOpacity = "";
    private readonly articleId;
    private readonly previewLang = "";
    public attachment = null;

    constructor(
        private newsService: NewsService,
        private route: ActivatedRoute,
        private navController: NavController,
        private authService: AuthService,
        private alertService: AlertService,
        private translateService: TranslateService,
        private browserService: BrowserService
    ) {
        if (this.route.snapshot.queryParams.lang) {
            this.previewLang = this.route.snapshot.queryParams.lang;
        }

        this.articleId = parseInt(this.route.snapshot.params.articleId, 0);
        this.listenForWysiwygLinks();
    }

    ngOnInit() {
        this.newsService
            .fetchArticle(this.articleId, this.previewLang)
            .subscribe(
                (data) => {
                    this.article = new Article(data);
                    this.articles = data["relatedArticles"].map(
                        (ra) => new Article(ra)
                    );
                    this.author = this.article.profile;
                    this.isDataLoaded = true;
                },
                (err) => {
                    this.navController.back();
                    this.alertService.warning(
                        this.translateService.instant("news-detail.no-access")
                    );
                }
            );
    }

    goBack() {
        this.navController.pop();
    }

    onScroll($event) {
        if ($event && $event.detail && $event.detail.scrollTop) {
            const scrollTop = $event.detail.scrollTop;
            this.toolbarOpacity = `hsla(0, 0%, 100%, ${scrollTop / 255})`;
            this.coloredToolbar = scrollTop >= 225;
        } else {
            this.toolbarOpacity = `hsla(0, 0%, 100%, 0)`;
            this.coloredToolbar = false;
        }
    }

    authorDetails(author) {
        this.navController.navigateForward([`/authors/${author.id}`]);
    }

    newsDetail(article: Article) {
        this.navController.navigateForward([`/news/${article.id}`]);
    }

    loadSecureImage(article) {
        const token = this.authService.authState.getValue().token;

        return article.getFeaturedImagePath() + `?token=${token}`;
    }

    fetchImageFromAuthState() {
        const token = this.authService.authState.getValue().token;

        if (this.author && this.author.photo) {
            return `${API_URL}/files/contacts/${this.author.photo}?token=${token}`;
        } else {
            return this.placeholderImageUrl;
        }
    }

    loadArticleSecureImage(article) {
        const token = this.authService.authState.getValue().token;

        return article.getFeaturedImagePath() + `?token=${token}`;
    }

    get articleComponents() {
        return this.article.components.filter((c: any) => {
            return c.component_type_id !== 5;
        });
    }

    get pdfComponents() {
        return this.article.components.filter((c: any) => {
            return c.component_type_id === 5;
        });
    }

    listenForWysiwygLinks() {
        document.addEventListener("click", (e) => {
            e.preventDefault();

            const el = e.target as HTMLAnchorElement;
            const dataset = el.dataset;

            if (dataset.redactorType === "articlelink" && dataset.link) {
                this.navController.navigateForward([`/news/${dataset.link}`]);
            }

            if (dataset.articleChapterLink && dataset.manualId) {
                this.navController.navigateForward(
                    [`/manuals/${dataset.manualId}`],
                    { queryParams: { scrollTo: dataset.articleChapterLink } }
                );
            }

            if (typeof el.href !== "undefined") {
                this.browserService.openLink(el.href);
            }
        });
    }

    navigateToPdf(id: number) {
        this.navController.navigateForward([`/preview/3/${id}`]);
    }

    get getPublishDate() {
        moment.locale(HelpersModule.getDeviceLanguage());
        return moment(this.article.published_at).format("D MMMM YYYY");
    }
}
