import { Injectable } from "@angular/core";
import { BaseHttpService } from "../base-http.service";
import { HttpClient } from "@angular/common/http";

@Injectable({
    providedIn: "root"
})
export class ContactsService extends BaseHttpService {
    constructor(private http: HttpClient) {
        super();
    }

    fetchByDepartment(departmentId) {
        return this.http.get<any>(
            `${this.baseUrl}/departments/${departmentId}/contacts`
        );
    }

    fetchContact(id) {
        return this.http.get<any>(`${this.baseUrl}/contacts/${id}`);
    }

    fetchByDivision(departmentId, divisionId) {
        return this.http.get<any>(
            `${this.baseUrl}/departments/${departmentId}/contacts?businessUnitId=${divisionId}`
        );
    }
}
