import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { Routes, RouterModule } from "@angular/router";

import { IonicModule } from "@ionic/angular";

import { DocumentsDetailPage } from "./documents-detail.page";
import { TranslateModule } from "@ngx-translate/core";
import { ComponentModule } from "../../components/component.module";
import { AngularSvgIconModule } from "angular-svg-icon";

const routes: Routes = [
    {
        path: "",
        component: DocumentsDetailPage
    }
];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        RouterModule.forChild(routes),
        TranslateModule,
        ComponentModule,
        AngularSvgIconModule
    ],
    declarations: [DocumentsDetailPage]
})
export class DocumentsDetailPageModule {}
