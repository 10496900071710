import { Component, ViewChild } from "@angular/core";
import { AlertService } from "../../services/alert/alert.service";
import { ActivatedRoute } from "@angular/router";
import { API_URL } from "../../../environments/environment";
import { AuthService } from "../../services/auth/auth.service";
import { MenuSliderComponent } from "../../components/menu-slider/menu-slider.component";
import { IonInput, ModalController, Platform } from "@ionic/angular";
import { SalariesService } from "../../services/salaries/salaries.service";
import { catchError } from "rxjs/operators";
import { TranslateService } from "@ngx-translate/core";
import { EmailsService } from "../../services/emails/emails.service";
import { PdfViewerComponent } from "ng2-pdf-viewer";
import { AccodeDocumentModalComponent } from "../../components/accode-document-modal/accode-document-modal.component";
import { DocumentsService } from "../../services/documents/documents.service";

@Component({
    selector: "app-file-preview",
    templateUrl: "./file-preview.page.html",
    styleUrls: ["./file-preview.page.scss"]
})
export class FilePreviewPage {
    @ViewChild(PdfViewerComponent) pdfViewer: PdfViewerComponent;
    @ViewChild("pdfActionsMenuSlider")
    pdfActionsMenuSlider: MenuSliderComponent;
    @ViewChild("emailInput") emailInput: IonInput;

    public types = {
        1: {
            name: "payslip",
            pinRequired: true,
            showDocumentName: false,
            canDownload: true,
            canMail: true,
            url: "salaries",
            title: "file-preview.types.payslip"
        },
        2: {
            name: "salary-attachment",
            pinRequired: true,
            showDocumentName: false,
            canDownload: true,
            canMail: true,
            url: "salary-attachments",
            title: "file-preview.types.salary-attachment"
        },
        3: {
            name: "news-attachment",
            pinRequired: false,
            showDocumentName: false,
            canDownload: true,
            canMail: true,
            url: "translations",
            title: "file-preview.types.news-attachment"
        },
        4: {
            name: "document",
            pinRequired: false,
            showDocumentName: false,
            canDownload: false,
            canMail: true,
            url: "documents/show",
            title: "file-preview.types.document"
        },
        5: {
            name: "personal-reports",
            pinRequired: false,
            showDocumentName: true,
            canDownload: false,
            canMail: true,
            url: "remotestorage",
            title: "file-preview.types.personal-report"
        },
        6: {
            name: "french-certificate",
            pinRequired: false,
            showDocumentName: true,
            canDownload: true,
            canMail: true,
            url: "remotestorage",
            title: "file-preview.types.french-certificate"
        }
    };

    public ZOOM_STEP = 0.25;
    public DEFAULT_ZOOM = 1;
    public pdfZoom = this.DEFAULT_ZOOM;
    public readonly attachmentUrl;
    public isPinVerified = false;
    public documentType = null;
    public emailPdf = false;
    public showDocumentName = false;
    public sendingMail = false;
    public email = this.authService.fetchAuthUser().email || "";
    public activeUserMenu = false;
    public readonly dossierId;
    public readonly documentName;
    public searchString = "";
    public totalHighlights = 0;
    public currentHighlight = 0;
    public initialized = false;
    public searchActive = false;
    public hasToAccept: boolean = false;
    public isAcceptedByUser: boolean = false;
    private modal: HTMLIonModalElement;

    constructor(
        private alertService: AlertService,
        private route: ActivatedRoute,
        private authService: AuthService,
        private salariesService: SalariesService,
        private translateService: TranslateService,
        private emailsService: EmailsService,
        private platform: Platform,
        private modalController: ModalController,
        private documentsService: DocumentsService
    ) {
        this.dossierId = parseInt(this.route.snapshot.params.id, 0);
        this.documentName =
            this.route.snapshot.queryParams.documentName || null;
        const token = this.authService.authState.getValue().token;
        this.documentType = this.types[this.route.snapshot.params.type];
        this.attachmentUrl = `${API_URL}`;
        if (
            this.documentType.name === "personal-reports" ||
            this.documentType.name === "french-certificate"
        ) {
            this.attachmentUrl += `/files`;
        }
        this.attachmentUrl += `/${this.documentType.url}/${this.dossierId}`;
        if (this.documentType.name === "news-attachment") {
            this.attachmentUrl += `/image`;
        }
        this.attachmentUrl += `?token=${token}`;
        this.isPinVerified = !this.documentType.pinRequired;

        this.hasToAccept =
            this.route.snapshot.queryParams?.hasToAccept ?? false;
        this.isAcceptedByUser =
            this.route.snapshot.queryParams?.isAcceptedByUser ?? false;
    }

    textLayerRendered(e: CustomEvent) {
        // A https link in a PDF doesn't work ons iOS.
        // This method converts the https link to http, so it should work in most cases.
        if (this.platform.is("ios") && this.platform.is("cordova")) {
            const pdfReport = document.getElementById("pdf-report-id");

            if (pdfReport) {
                const externalLinks = pdfReport.getElementsByTagName("a");

                Array.from(externalLinks).forEach((externalLink) => {
                    const replaceUrl = externalLink.href.replace(
                        "https://",
                        "http://"
                    );
                    externalLink.setAttribute("target", "_blank");
                    externalLink.href = replaceUrl;
                });
            }
        }
    }

    search() {
        this.pdfViewer.pdfFindController.executeCommand(
            "findagain",
            this.getSearchObject(undefined)
        );

        this.totalHighlights = 0;
        this.currentHighlight = 0;

        this.initializeHighlights();
    }

    nextMatch() {
        this.pdfViewer.pdfFindController.executeCommand(
            "findagain",
            this.getSearchObject(false)
        );
        this.setCurrentHighlight(true);
    }

    prevMatch() {
        this.pdfViewer.pdfFindController.executeCommand(
            "findagain",
            this.getSearchObject(true)
        );
        this.setCurrentHighlight(false);
    }

    getSearchObject(findPrevious) {
        return {
            caseSensitive: false,
            findPrevious,
            highlightAll: true,
            phraseSearch: true,
            query: this.searchString
        };
    }

    // https://github.com/VadimDez/ng2-pdf-viewer/issues/544
    initializeHighlights() {
        if (!this.initialized) {
            this.pdfViewer.pdfViewer.eventBus.on(
                "updatefindmatchescount",
                (data) => {
                    this.currentHighlight = data.matchesCount.current;
                    this.totalHighlights = data.matchesCount.total;
                }
            );

            this.initialized = true;
        }
    }

    setCurrentHighlight(next = true) {
        const highlights = document.querySelectorAll("#pdfContent .highlight");
        const selected = document.querySelector("#pdfContent .selected");

        const highlightIndex =
            [].findIndex.call(highlights, (highlight) => {
                return highlight === selected;
            }) + 1;

        if (this.platform.is("ios") && this.platform.is("cordova")) {
            selected.scrollIntoView(true);
        }

        if (this.totalHighlights > 0) {
            if (next) {
                if (this.currentHighlight < this.totalHighlights) {
                    this.currentHighlight = highlightIndex + 1;
                } else {
                    this.currentHighlight = 1;
                }
            } else {
                if (this.currentHighlight > 1) {
                    this.currentHighlight = highlightIndex - 1;
                } else {
                    this.currentHighlight = this.totalHighlights;
                }
            }
        }
    }

    closeSearch() {
        this.searchString = "";
        this.pdfViewer.pdfFindController.executeCommand("findagain", {
            caseSensitive: false,
            findPrevious: false,
            highlightAll: false,
            phraseSearch: false,
            query: this.searchString
        });
        this.searchActive = false;
        this.currentHighlight = 0;
        this.totalHighlights = 0;
    }

    openSearch(value) {
        this.searchActive = true;

        if (value === "mobile") {
            this.pdfActionsMenuSlider.close();
        }

        if (value === "desktop") {
            this.activeUserMenu = false;
        }
    }

    toggleMenu() {
        this.activeUserMenu = !this.activeUserMenu;
    }

    showEmailDesktop() {
        this.activeUserMenu = false;
        this.emailPdf = true;
        this.pdfActionsMenuSlider.open();
    }

    onPinEntered(isCorrect) {
        this.isPinVerified = isCorrect;

        if (!isCorrect) {
            this.alertService.warning("Pin komt niet overeen.");
        }
    }

    showPDFActionsMenuSlide() {
        setTimeout(() => {
            this.pdfActionsMenuSlider.open();
            this.emailPdf = false;
        }, 200);
    }

    showPDFActionsMenuSlideMail() {
        setTimeout(() => {
            this.pdfActionsMenuSlider.open();
            this.emailPdf = true;
        }, 200);
    }

    showEmail() {
        this.emailPdf = !this.emailPdf;
    }

    emailAttachment() {
        const email = this.email.toString();
        this.sendingMail = true;

        this.emailsService
            .emailAttachment(this.dossierId, email, this.documentType.url)
            .pipe(
                catchError((err: any) => {
                    this.sendingMail = false;
                    this.emailInput.value = "";
                    this.alertService.warning(
                        this.translateService.instant("pdf.mail-error")
                    );
                    return err;
                })
            )
            .subscribe(() => {
                this.alertService.success(
                    this.translateService.instant("pdf.mail-sent")
                );
                this.sendingMail = false;
                this.emailInput.value = "";
                this.pdfActionsMenuSlider.close();
            });
    }

    async openToggleAccodeModal() {
        this.modal = await this.modalController.create({
            component: AccodeDocumentModalComponent,
            cssClass: "accode-document-modal",
            animated: true,
            breakpoints: !this.isDesktop ? [0, 1] : undefined,
            initialBreakpoint: 1,
            canDismiss: true,
            handle: false,
            mode: "md",
            componentProps: {
                callback: this.toggleAccode.bind(this)
            }
        });
        return await this.modal.present();
    }

    get isDesktop(): boolean {
        return this.platform.is("desktop");
    }

    async toggleAccode() {
        this.documentsService
            .approveSpecificDocument(this.dossierId)
            .subscribe(() => {
                this.modal.dismiss();
                this.alertService.accoded(
                    this.translateService.instant(
                        "accoding-documents.accoding-documents-success"
                    )
                );
                this.isAcceptedByUser = true;
            });
    }

    public zoomIn() {
        this.pdfZoom += this.ZOOM_STEP;
    }

    public zoomOut() {
        if (this.pdfZoom > this.DEFAULT_ZOOM) {
            this.pdfZoom -= this.ZOOM_STEP;
        }
    }

    public resetZoom() {
        this.pdfZoom = this.DEFAULT_ZOOM;
    }

    isMobile() {
        return !this.platform.is("desktop");
    }
}
