<ion-content>
    <div class="desktop-view">
        <div class="text">
            <h3>PIN code.</h3>
            <p>Vul je PIN code in om uw persoonlijke informatie te bekijken.</p>
        </div>

        <div (click)="focusLogin()" class="pin-input-container">
            <div [className]="isFilled(1)" class="pin-field"></div>
            <div [className]="isFilled(2)" class="pin-field"></div>
            <div [className]="isFilled(3)" class="pin-field"></div>
            <div [className]="isFilled(4)" class="pin-field"></div>
            <div [className]="isFilled(5)" class="pin-field"></div>
        </div>

        <ion-input
            #pinInput
            (ionInput)="pinChange()"
            class="input"
            inputmode="numeric"
            maxlength="5"
        ></ion-input>
    </div>
</ion-content>
