import { Injectable } from "@angular/core";
import { BaseHttpService } from "../base-http.service";
import { HttpClient } from "@angular/common/http";

@Injectable({
    providedIn: "root"
})
export class ManualsService extends BaseHttpService {
    constructor(private http: HttpClient) {
        super();
    }

    fetchList() {
        return this.http.get<any>(`${this.baseUrl}/manuals`);
    }

    fetchManualChapters(id) {
        return this.http.get<any>(`${this.baseUrl}/manuals/${id}/chapters`);
    }

    fetchManualChanges(id, manualVersionId) {
        return this.http.get<any>(
            `${this.baseUrl}/manuals/${id}/versions/${manualVersionId}/changes`
        );
    }

    approveVersion(id, manualVersionId) {
        return this.http.post(
            `${this.baseUrl}/manuals/${id}/versions/${manualVersionId}/approve`,
            null
        );
    }
}
