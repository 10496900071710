import { Injectable } from "@angular/core";
import { BaseHttpService } from "../base-http.service";
import { HttpClient } from "@angular/common/http";

@Injectable({
    providedIn: "root"
})
export class AccountsService extends BaseHttpService {
    constructor(private http: HttpClient) {
        super();
    }

    profile() {
        return this.http.get<any>(`${this.baseUrl}/account`);
    }

    patch(data) {
        return this.http.patch(`${this.baseUrl}/account`, data);
    }

    storeProfileImage(base64Image) {
        return this.http.post(`${this.baseUrl}/account/image`, {
            image: base64Image
        });
    }

    storeDeviceToken(token) {
        return this.http.post(`${this.baseUrl}/account/device-token`, {
            token
        });
    }
}
