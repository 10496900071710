import moment from "moment";

export class DocumentModel {
    id: number;
    extension: string;
    name: string;
    type: string;
    parent_id: number;
    lastUpdate: moment.Moment;
    lastOpened: Date;
    docType: string = null;
    url: string = null;
    hasToAccept: Boolean = false;
    isAcceptedByUser: Boolean = false;

    constructor(item) {
        if (!item) {
            return;
        }

        this.id = item.id;
        this.extension = item.extension;
        this.name = item.name;
        this.type = item.type;
        this.parent_id = item.parent_id;
        this.lastUpdate = moment.unix(item.lastUpdate);
        this.lastOpened = new Date(Date.now());
        this.docType = item.docType;
        this.url = item.url;
        this.hasToAccept = item.hasToAccept;
        this.isAcceptedByUser = item.isAcceptedByUser;
    }
}
