import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { Routes, RouterModule } from "@angular/router";
import { IonicModule } from "@ionic/angular";

import { DocumentsSearchPage } from "./documents-search.page";
import { TranslateModule } from "@ngx-translate/core";
import { AngularSvgIconModule } from "angular-svg-icon";

const routes: Routes = [
    {
        path: "",
        component: DocumentsSearchPage
    }
];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        RouterModule.forChild(routes),
        TranslateModule,
        AngularSvgIconModule
    ],
    declarations: [DocumentsSearchPage]
})
export class DocumentsSearchModule {}
