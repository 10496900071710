<ion-header no-border>
    <ion-toolbar class="grid-padding">
        <ion-title>Privacy Policy</ion-title>
    </ion-toolbar>
</ion-header>

<ion-content>
    <div class="on-the-way">
        <div class="container">
            <div class="WordSection1">
                <p
                    class="MsoNormal"
                    style="margin: 0cm; line-height: 107%; text-indent: 0cm"
                >
                    <b>
                        <span style="font-size: 16pt; line-height: 107%">
                            Privacy Policy of AB Track
                        </span>
                    </b>
                </p>

                <p class="MsoNormal" style="margin-left: -0.25pt">
                    In order to receive information about your Personal Data,
                    the purposes and the parties the Data is shared with,
                    contact the Owner.
                </p>

                <p
                    class="MsoNormal"
                    style="
                        margin-top: 0cm;
                        margin-right: 0cm;
                        margin-bottom: 2.95pt;
                        margin-left: 0cm;
                        line-height: 107%;
                        text-indent: 0cm;
                    "
                >
                     
                </p>

                <h1 style="margin-left: -0.25pt">Owner and Data Controller</h1>

                <p
                    class="MsoNormal"
                    style="margin: 0cm; line-height: 107%; text-indent: 0cm"
                >
                     
                </p>

                <h2 style="margin-left: -0.25pt">Types of Data collected</h2>

                <p class="MsoNormal" style="margin-left: -0.25pt">
                    The owner does not provide a list of Personal Data types
                    collected.
                </p>

                <p
                    class="MsoNormal"
                    style="margin: 0cm; line-height: 107%; text-indent: 0cm"
                >
                     
                </p>

                <p class="MsoNormal" style="margin-left: -0.25pt">
                    Complete details on each type of Personal Data collected are
                    provided in the dedicated sections of this privacy policy or
                    by specific explanation texts displayed prior to the Data
                    collection.
                </p>

                <p class="MsoNormal" style="margin-left: -0.25pt">
                    Personal Data may be freely provided by the User, or, in
                    case of Usage Data, collected automatically when using this
                    Application.
                </p>

                <p class="MsoNormal" style="margin-left: -0.25pt">
                    Unless specified otherwise, all Data requested by this
                    Application is mandatory and failure to provide this Data
                    may make it impossible for this Application to provide its
                    services. In cases where this Application specifically
                    states that some Data is not mandatory, Users are free not
                    to communicate this Data without consequences to the
                    availability or the functioning of the Service.
                </p>

                <p class="MsoNormal" style="margin-left: -0.25pt">
                    Users who are uncertain about which Personal Data is
                    mandatory are welcome to contact the Owner.
                </p>

                <p class="MsoNormal" style="margin-left: -0.25pt">
                    Any use of Cookies – or of other tracking tools – by this
                    Application or by the owners of third-party services used by
                    this Application serves the purpose of providing the Service
                    required by the User, in addition to any other purposes
                    described in the present document and in the Cookie Policy,
                    if available.
                </p>

                <p
                    class="MsoNormal"
                    style="margin: 0cm; line-height: 107%; text-indent: 0cm"
                >
                     
                </p>

                <p class="MsoNormal" style="margin-left: -0.25pt">
                    Users are responsible for any third-party Personal Data
                    obtained, published or shared through this Application and
                    confirm that they have the third party's consent to provide
                    the Data to the Owner.
                </p>

                <p
                    class="MsoNormal"
                    style="
                        margin-top: 0cm;
                        margin-right: 0cm;
                        margin-bottom: 2.95pt;
                        margin-left: 0cm;
                        line-height: 107%;
                        text-indent: 0cm;
                    "
                >
                     
                </p>

                <h1 style="margin-left: -0.25pt">
                    Mode and place of processing the Data
                </h1>

                <p
                    class="MsoNormal"
                    style="margin: 0cm; line-height: 107%; text-indent: 0cm"
                >
                    <b> </b>
                </p>

                <h2 style="margin-left: -0.25pt">Methods of processing</h2>

                <p class="MsoNormal" style="margin-left: -0.25pt">
                    The Owner takes appropriate security measures to prevent
                    unauthorized access, disclosure, modification, or
                    unauthorized destruction of the Data.
                </p>

                <p class="MsoNormal" style="margin-left: -0.25pt">
                    The Data processing is carried out using computers and/or IT
                    enabled tools, following organizational procedures and modes
                    strictly related to the purposes indicated. In addition to
                    the Owner, in some cases, the Data may be accessible to
                    certain types of persons in charge, involved with the
                    operation of this Application (administration, sales,
                    marketing, legal, system administration) or external parties
                    (such as third-party technical service providers, mail
                    carriers, hosting providers, IT companies, communications
                    agencies) appointed, if necessary, as Data Processors by the
                    Owner. The updated list of these parties may be requested
                    from the Owner at any time.
                </p>

                <p class="MsoNormal" style="margin-left: -0.25pt">
                    Legal basis of processing
                </p>

                <p class="MsoNormal" style="margin-left: -0.25pt">
                    The Owner may process Personal Data relating to Users if one
                    of the following applies:
                </p>

                <p
                    class="MsoNormal"
                    style="
                        margin-top: 0cm;
                        margin-right: 0cm;
                        margin-bottom: 0cm;
                        margin-left: 36pt;
                        line-height: 107%;
                        text-indent: -18pt;
                    "
                >
                    <span style="font-family: Arial, sans-serif">
                        •
                        <span style="font: 7pt Times New Roman">
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span>
                    </span>
                    Users have given their consent for one or more specific
                    purposes. Note:
                </p>

                <p class="MsoNormal" style="margin-left: 36.5pt">
                    Under some legislations the Owner may be allowed to process
                    Personal Data until the User objects to such processing
                    (“opt-out”), without having
                </p>

                <p class="MsoNormal" style="margin-left: 36.5pt">
                    to rely on consent or any other of the following legal
                    bases. This, however, does not apply, whenever the
                    processing of Personal Data is subject to European data
                    protection law;
                </p>

                <p
                    class="MsoNormal"
                    style="margin-left: 36pt; text-indent: -18pt"
                >
                    <span style="font-family: Arial, sans-serif">
                        •
                        <span style="font: 7pt Times New Roman">
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span>
                    </span>
                    provision of Data is necessary for the performance of an
                    agreement with the User and/or for any pre-contractual
                    obligations thereof;
                </p>

                <p
                    class="MsoNormal"
                    style="margin-left: 36pt; text-indent: -18pt"
                >
                    <span style="font-family: Arial, sans-serif">
                        •
                        <span style="font: 7pt Times New Roman">
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span>
                    </span>
                    which the Owner is subject;
                </p>

                <p
                    class="MsoNormal"
                    style="margin-left: 36pt; text-indent: -18pt"
                >
                    <span style="font-family: Arial, sans-serif">
                        •
                        <span style="font: 7pt Times New Roman">
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span>
                    </span>
                    processing is related to a task that is carried out in the
                    public interest or in the exercise of official authority
                    vested in the Owner;
                </p>

                <p
                    class="MsoNormal"
                    style="margin-left: 36pt; text-indent: -18pt"
                >
                    <span style="font-family: Arial, sans-serif">
                        •
                        <span style="font: 7pt Times New Roman">
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span>
                    </span>
                    processing is necessary for the purposes of the legitimate
                    interests pursued by the Owner or by a third party.
                </p>

                <p
                    class="MsoNormal"
                    style="margin: 0cm; line-height: 107%; text-indent: 0cm"
                >
                     
                </p>

                <p class="MsoNormal" style="margin-left: -0.25pt">
                    In any case, the Owner will gladly help to clarify the
                    specific legal basis that applies to the processing, and in
                    particular whether the provision of Personal Data is a
                    statutory or contractual requirement, or a requirement
                    necessary to enter into a contract.
                </p>

                <p
                    class="MsoNormal"
                    style="margin: 0cm; line-height: 107%; text-indent: 0cm"
                >
                     
                </p>

                <h2 style="margin-left: -0.25pt">Place</h2>

                <p class="MsoNormal" style="margin-left: -0.25pt">
                    The Data is processed at the Owner's operating offices and
                    in any other places where the parties involved in the
                    processing are located.
                </p>

                <p
                    class="MsoNormal"
                    style="margin: 0cm; line-height: 107%; text-indent: 0cm"
                >
                     
                </p>

                <p class="MsoNormal" style="margin-left: -0.25pt">
                    Depending on the User's location, data transfers may involve
                    transferring the User's Data to a country other than their
                    own. To find out more about the place of processing of such
                    transferred Data, Users can check the section containing
                    details about the processing of Personal Data.
                </p>

                <p
                    class="MsoNormal"
                    style="margin: 0cm; line-height: 107%; text-indent: 0cm"
                >
                     
                </p>

                <p class="MsoNormal" style="margin-left: -0.25pt">
                    If broader protection standards are applicable, Users are
                    also entitled to learn about the legal basis of Data
                    transfers to a country outside the European Union or to any
                    international organization governed by public international
                    law or set up by two or more countries, such as the UN, and
                    about the security measures taken by the Owner to safeguard
                    their Data.
                </p>

                <p
                    class="MsoNormal"
                    style="margin: 0cm; line-height: 107%; text-indent: 0cm"
                >
                     
                </p>

                <p class="MsoNormal" style="margin-left: -0.25pt">
                    If any such transfer takes place, Users can find out more by
                    checking the relevant sections of this document or inquire
                    with the Owner using the information provided in the contact
                    section.
                </p>

                <p
                    class="MsoNormal"
                    style="margin: 0cm; line-height: 107%; text-indent: 0cm"
                >
                     
                </p>

                <h2 style="margin-left: -0.25pt">Retention time</h2>

                <p class="MsoNormal" style="margin-left: -0.25pt">
                    Personal Data shall be processed and stored for as long as
                    required by the purpose they have been collected for.
                </p>

                <p class="MsoNormal" style="margin-left: -0.25pt">Therefore:</p>

                <p
                    class="MsoNormal"
                    style="margin-left: 36pt; text-indent: -18pt"
                >
                    <span style="font-family: Arial, sans-serif">
                        •
                        <span style="font: 7pt Times New Roman">
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span>
                    </span>
                    Personal Data collected for purposes related to the
                    performance of a contract between the Owner and the User
                    shall be retained until such contract has been fully
                    performed.
                </p>

                <p
                    class="MsoNormal"
                    style="margin-left: 36pt; text-indent: -18pt"
                >
                    <span style="font-family: Arial, sans-serif">
                        •
                        <span style="font: 7pt Times New Roman">
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span>
                    </span>
                    Personal Data collected for the purposes of the Owner’s
                    legitimate interests shall be retained as long as needed to
                    fulfill such purposes. Users may find specific information
                    regarding the legitimate interests pursued by the Owner
                    within the relevant sections of this document or by
                    contacting the Owner.
                </p>

                <p
                    class="MsoNormal"
                    style="margin: 0cm; line-height: 107%; text-indent: 0cm"
                >
                              
                </p>

                <p
                    class="MsoNormal"
                    style="margin: 0cm; line-height: 107%; text-indent: 0cm"
                >
                     
                </p>

                <p class="MsoNormal" style="margin-left: -0.25pt">
                    The Owner may be allowed to retain Personal Data for a
                    longer period whenever the User has given consent to such
                    processing, as long as such consent is not withdrawn.
                    Furthermore, the Owner may be obliged to retain Personal
                    Data for a longer period whenever required to do so for the
                    performance of a legal obligation or upon order of an
                    authority.
                </p>

                <p
                    class="MsoNormal"
                    style="margin: 0cm; line-height: 107%; text-indent: 0cm"
                >
                     
                </p>

                <p class="MsoNormal" style="margin-left: -0.25pt">
                    Once the retention period expires, Personal Data shall be
                    deleted. Therefore, the right to access, the right to
                    erasure, the right to rectification and the right to data
                    portability cannot be enforced after expiration of the
                    retention period.
                </p>

                <p
                    class="MsoNormal"
                    style="
                        margin-top: 0cm;
                        margin-right: 0cm;
                        margin-bottom: 2.9pt;
                        margin-left: 0cm;
                        line-height: 107%;
                        text-indent: 0cm;
                    "
                >
                     
                </p>

                <h1 style="margin-left: -0.25pt">The rights of Users</h1>

                <p class="MsoNormal" style="margin-left: -0.25pt">
                    Users may exercise certain rights regarding their Data
                    processed by the Owner.
                </p>

                <p
                    class="MsoNormal"
                    style="margin: 0cm; line-height: 107%; text-indent: 0cm"
                >
                     
                </p>

                <p class="MsoNormal" style="margin-left: -0.25pt">
                    Users entitled to broader protection standards may exercise
                    any of the rights described below. In all other cases, Users
                    may inquire with the Owner to find out which rights apply to
                    them.
                </p>

                <p class="MsoNormal" style="margin-left: -0.25pt">
                    In particular, Users have the right to do the following:
                </p>

                <p
                    class="MsoNormal"
                    style="margin-left: 36pt; text-indent: -18pt"
                >
                    <span style="font-family: Arial, sans-serif">
                        •
                        <span style="font: 7pt Times New Roman">
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span>
                    </span>
                    <b>Withdraw their consent at any time.</b>
                    Users have the right to withdraw consent where they have
                    previously given their consent to the processing of their
                    Personal Data.
                </p>

                <p
                    class="MsoNormal"
                    style="margin-left: 36pt; text-indent: -18pt"
                >
                    <span style="font-family: Arial, sans-serif">
                        •
                        <span style="font: 7pt Times New Roman">
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span>
                    </span>
                    <b>Object to processing of their Data.</b>
                    Users have the right to object to the processing of their
                    Data if the processing is carried out on a legal basis other
                    than consent. Further details are provided in the dedicated
                    section below.
                </p>

                <p
                    class="MsoNormal"
                    style="margin-left: 36pt; text-indent: -18pt"
                >
                    <span style="font-family: Arial, sans-serif">
                        •
                        <span style="font: 7pt Times New Roman">
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span>
                    </span>
                    <b>Access their Data.</b>
                    Users have the right to learn if Data is being processed by
                    the Owner, obtain disclosure regarding certain aspects of
                    the processing and obtain a copy of the Data undergoing
                    processing.
                </p>

                <p
                    class="MsoNormal"
                    style="margin-left: 36pt; text-indent: -18pt"
                >
                    <span style="font-family: Arial, sans-serif">
                        •
                        <span style="font: 7pt Times New Roman">
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span>
                    </span>
                    <b>Verify and seek rectification.</b>
                    Users have the right to verify the accuracy of their Data
                    and ask for it to be updated or corrected.
                </p>

                <p
                    class="MsoNormal"
                    style="margin-left: 36pt; text-indent: -18pt"
                >
                    <span style="font-family: Arial, sans-serif">
                        •
                        <span style="font: 7pt Times New Roman">
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span>
                    </span>
                    <b>Restrict the processing of their Data.</b>
                    Users have the right, under certain circumstances, to
                    restrict the processing of their Data. In this case, the
                    Owner will not process their Data for any purpose other than
                    storing it.
                </p>

                <p
                    class="MsoNormal"
                    style="margin-left: 36pt; text-indent: -18pt"
                >
                    <span style="font-family: Arial, sans-serif">
                        •
                        <span style="font: 7pt Times New Roman">
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span>
                    </span>
                    <b>
                        Have their Personal Data deleted or otherwise removed.
                    </b>
                    Users have the right, under certain circumstances, to obtain
                    the erasure of their Data from the Owner.
                </p>

                <p
                    class="MsoNormal"
                    style="margin-left: 36pt; text-indent: -18pt"
                >
                    <span style="font-family: Arial, sans-serif">
                        •
                        <span style="font: 7pt Times New Roman">
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span>
                    </span>
                    <b>
                        Receive their Data and have it transferred to another
                        controller.
                    </b>
                    Users have the right to receive their Data in a structured,
                    commonly used and machine readable format and, if
                    technically feasible, to have it transmitted to another
                    controller without any hindrance. This provision is
                    applicable provided that the Data is processed by automated
                    means and that the processing is based on the User's
                    consent, on a contract which the User is part of or on
                    pre-contractual obligations thereof.
                </p>

                <p
                    class="MsoNormal"
                    style="margin-left: 36pt; text-indent: -18pt"
                >
                    <span style="font-family: Arial, sans-serif">
                        •
                        <span style="font: 7pt Times New Roman">
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span>
                    </span>
                    <b>Lodge a complaint.</b>
                    Users have the right to bring a claim before their competent
                    data protection authority.
                </p>

                <p
                    class="MsoNormal"
                    style="margin: 0cm; line-height: 107%; text-indent: 0cm"
                >
                     
                </p>

                <h2 style="margin-left: -0.25pt">
                    Details about the right to object to processing
                </h2>

                <p class="MsoNormal" style="margin-left: -0.25pt">
                    Where Personal Data is processed for a public interest, in
                    the exercise of an official authority vested in the Owner or
                    for the purposes of the legitimate interests pursued by the
                    Owner, Users may object to such processing by providing a
                    ground related to their particular situation to justify the
                    objection.
                </p>

                <p
                    class="MsoNormal"
                    style="margin: 0cm; line-height: 107%; text-indent: 0cm"
                >
                     
                </p>

                <p class="MsoNormal" style="margin-left: -0.25pt">
                    Users must know that, however, should their Personal Data be
                    processed for direct marketing purposes, they can object to
                    that processing at any time without providing any
                    justification. To learn, whether the Owner is processing
                    Personal Data for direct marketing purposes, Users may refer
                    to the relevant sections of this document.
                </p>

                <p
                    class="MsoNormal"
                    style="margin: 0cm; line-height: 107%; text-indent: 0cm"
                >
                     
                </p>

                <h2 style="margin-left: -0.25pt">
                    How to exercise these rights
                </h2>

                <p class="MsoNormal" style="margin-left: -0.25pt">
                    Any requests to exercise User rights can be directed to the
                    Owner through the contact details provided in this document.
                    These requests can be exercised free of charge and will be
                    addressed by the Owner as early as possible and always
                    within one month.
                </p>

                <p
                    class="MsoNormal"
                    style="
                        margin-top: 0cm;
                        margin-right: 0cm;
                        margin-bottom: 2.9pt;
                        margin-left: 0cm;
                        line-height: 107%;
                        text-indent: 0cm;
                    "
                >
                     
                </p>

                <h1 style="margin-left: -0.25pt">
                    Applicability of broader protection standards
                </h1>

                <p class="MsoNormal" style="margin-left: -0.25pt">
                    While most provisions of this document concern all Users,
                    some provisions expressly only apply if the processing of
                    Personal Data is subject to broader protection standards.
                </p>

                <p
                    class="MsoNormal"
                    style="margin-left: 36pt; text-indent: -18pt"
                >
                    <span style="font-family: Arial, sans-serif">
                        •
                        <span style="font: 7pt Times New Roman">
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span>
                    </span>
                    Such broader protection standards apply when the processing:
                    is performed by an Owner based within the EU;
                </p>

                <p
                    class="MsoNormal"
                    style="margin-left: 36pt; text-indent: -18pt"
                >
                    <span style="font-family: Arial, sans-serif">
                        •
                        <span style="font: 7pt Times New Roman">
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span>
                    </span>
                    concerns the Personal Data of Users who are in the EU and is
                    related to the offering of paid or unpaid goods or services,
                    to such Users;
                </p>

                <p
                    class="MsoNormal"
                    style="margin-left: 36pt; text-indent: -18pt"
                >
                    <span style="font-family: Arial, sans-serif">
                        •
                        <span style="font: 7pt Times New Roman">
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span>
                    </span>
                    concerns the Personal Data of Users who are in the EU and
                    allows the Owner to monitor such Users’ behavior taking
                    place in the EU.
                </p>

                <p
                    class="MsoNormal"
                    style="
                        margin-top: 0cm;
                        margin-right: 0cm;
                        margin-bottom: 2.95pt;
                        margin-left: 0cm;
                        line-height: 107%;
                        text-indent: 0cm;
                    "
                >
                     
                </p>

                <h1 style="margin-left: -0.25pt">
                    Additional information about Data collection and processing
                </h1>

                <h2 style="margin-left: -0.25pt">Legal action</h2>

                <p class="MsoNormal" style="margin-left: -0.25pt">
                    The User's Personal Data may be used for legal purposes by
                    the Owner in Court or in the stages leading to possible
                    legal action arising from improper use of this Application
                    or the related Services.
                </p>

                <p class="MsoNormal" style="margin-left: -0.25pt">
                    The User declares to be aware that the Owner may be required
                    to reveal personal data upon request of public authorities.
                </p>

                <p
                    class="MsoNormal"
                    style="margin: 0cm; line-height: 107%; text-indent: 0cm"
                >
                     
                </p>

                <h2 style="margin-left: -0.25pt">
                    Additional information about User's Personal Data
                </h2>

                <p class="MsoNormal" style="margin-left: -0.25pt">
                    In addition to the information contained in this privacy
                    policy, this Application may provide the User with
                    additional and contextual information concerning particular
                    Services or the collection and processing of Personal Data
                    upon request.
                </p>

                <p
                    class="MsoNormal"
                    style="margin: 0cm; line-height: 107%; text-indent: 0cm"
                >
                     
                </p>

                <h2 style="margin-left: -0.25pt">
                    System logs and maintenance
                </h2>

                <p class="MsoNormal" style="margin-left: -0.25pt">
                    For operation and maintenance purposes, this Application and
                    any third-party services may collect files that record
                    interaction with this Application (System logs) use other
                    Personal Data (such as the IP Address) for this purpose.
                </p>

                <p
                    class="MsoNormal"
                    style="margin: 0cm; line-height: 107%; text-indent: 0cm"
                >
                     
                </p>

                <h2 style="margin-left: -0.25pt">
                    Information not contained in this policy
                </h2>

                <p class="MsoNormal" style="margin-left: -0.25pt">
                    More details concerning the collection or processing of
                    Personal Data may be requested from the Owner at any time.
                    Please see the contact information at the beginning of this
                    document.
                </p>

                <p
                    class="MsoNormal"
                    style="margin: 0cm; line-height: 107%; text-indent: 0cm"
                >
                     
                </p>

                <h2 style="margin-left: -0.25pt">
                    How “Do Not Track” requests are handled
                </h2>

                <p class="MsoNormal" style="margin-left: -0.25pt">
                    This Application does not support “Do Not Track” requests.
                </p>

                <p class="MsoNormal" style="margin-left: -0.25pt">
                    To determine whether any of the third-party services it uses
                    honor the “Do Not Track” requests, please read their privacy
                    policies.
                </p>

                <p
                    class="MsoNormal"
                    style="margin: 0cm; line-height: 107%; text-indent: 0cm"
                >
                     
                </p>

                <h2 style="margin-left: -0.25pt">
                    Changes to this privacy policy
                </h2>

                <p class="MsoNormal" style="margin-left: -0.25pt">
                    The Owner reserves the right to make changes to this privacy
                    policy at any time by notifying its Users on this page and
                    possibly within this Application and/or - as far as
                    technically and legally feasible - sending a notice to Users
                    via any contact information available to the Owner. It is
                    strongly recommended to check this page often, referring to
                    the date of the last modification listed at the bottom.
                </p>

                <p
                    class="MsoNormal"
                    style="margin: 0cm; line-height: 107%; text-indent: 0cm"
                >
                     
                </p>

                <p class="MsoNormal" style="margin-left: -0.25pt">
                    Should the changes affect processing activities performed on
                    the basis of the User’s consent, the Owner shall collect new
                    consent from the User, where required.
                </p>
            </div>
        </div>
    </div>
</ion-content>
