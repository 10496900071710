<ion-content class="ion-no-padding">
    <div class="container">
        <div class="text">
            <h3>{{ 'terms-and-conditions.privacy-policy' | translate }}</h3>
            <p>{{ 'terms-and-conditions.title' | translate}}</p>
            <p>
                <span>
                    {{'terms-and-conditions.subtitle-first' | translate}}
                </span>
                <a routerLink="/privacy-policy">
                    {{'terms-and-conditions.privacy-policy' | translate |
                    lowercase}}
                </a>
                <span>
                    {{'terms-and-conditions.subtitle-second' | translate}}
                </span>
            </p>
            <div class="contact-hr-box">
                <a (click)="acceptTerms()">
                    {{ 'terms-and-conditions.accept' | translate }}
                </a>
            </div>
        </div>

        <img class="phones-image" src="/assets/images/phones.png" />
    </div>
</ion-content>
