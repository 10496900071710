import { Component, ViewChild } from "@angular/core";
import { MenuSliderComponent } from "../../components/menu-slider/menu-slider.component";
import { AuthService } from "../../services/auth/auth.service";
import { AuthUser } from "../../entities/AuthUser";
import { TranslateService } from "@ngx-translate/core";

@Component({
    selector: "app-master",
    templateUrl: "master.page.html",
    styleUrls: ["master.page.scss"]
})
export class MasterPage {
    @ViewChild("mainMenu") mainMenu: MenuSliderComponent;
    public profile = this.authService.userProfileState.getValue();
    public menuItems = [];
    public baseMenuItems = [];
    private menuState = false;

    constructor(
        private authService: AuthService,
        private translateService: TranslateService
    ) {
        this.authService.userProfileState
            .asObservable()
            .subscribe((authUser: AuthUser) => {
                this.profile = authUser;
            });

        this.getMenuItems();
    }

    async getMenuItems() {
        const translations = await this.translateService
            .get("navigation")
            .toPromise();

        this.baseMenuItems = [
            {
                name: translations.profile,
                routerLink: "/profile",
                routerLinkOptions: {},
                icon: "mdi_account_circle",
                hasIf: true
            }
        ];

        this.menuItems = [
            {
                name: translations.events,
                routerLink: "/events",
                routerLinkOptions: {},
                icon: "cake",
                hasIf: true
            },
            {
                name: translations["locations-on-route"],
                routerLink: "/tank-locations-car-washes",
                routerLinkOptions: {},
                icon: "mdi_local_gas_station",
                hasIf: this.profile.hasPermission("tanklocaties-wasstraten")
            },
            {
                name: translations.expenses,
                routerLink: "/expenses",
                routerLinkOptions: {},
                icon: "mdi_expenses",
                hasIf: this.profile.hasPermission("expenses")
            },
            {
                name: translations.colleagues,
                routerLink: "/colleagues",
                routerLinkOptions: {},
                icon: "contacts",
                hasIf: true
            },
            {
                name: translations.ideas,
                routerLink: "/ideas",
                routerLinkOptions: {},
                icon: "ideas",
                hasIf: true
            },
            {
                name: translations.furlough,
                routerLink: "/furloughs",
                routerLinkOptions: {},
                icon: "furlough",
                hasIf: this.profile.hasPermission("furlough")
            },
            {
                name: translations["my-favorite-articles"],
                routerLink: "/news/favorite",
                routerLinkOptions: { exact: true },
                icon: "baseline-photo-24px",
                hasIf: true
            },
            {
                name: translations["my-service-schedule"],
                routerLink: "/content-page/1",
                routerLinkOptions: {},
                icon: "furlough",
                hasIf: true
            }
        ]
            .filter((item) => {
                return item.hasIf;
            })
            .sort((a, b) => (a.name < b.name ? -1 : 1));

        return translations;
    }

    onMenuStateChange() {
        if (!this.menuState) {
            this.menuState = true;
            this.mainMenu.open();
        } else {
            this.menuState = false;
            this.mainMenu.close();
        }
    }

    onMenuClosed() {
        this.menuState = false;
    }
}
