import { Component, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { NavController, IonInput, IonContent, Platform } from "@ionic/angular";
import moment from "moment";
import { AppEventsService as Events } from "../../services/app-events.service";
import { Hilitor } from "../../libs/hilitor";
import { AuthService } from "../../services/auth/auth.service";
import { ManualsService } from "../../services/manuals/manuals.service";

@Component({
    selector: "app-manual",
    templateUrl: "./manual.page.html",
    styleUrls: ["./manual.page.scss"]
})
export class ManualPage {
    @ViewChild("searchInput") searchInput: IonInput;
    @ViewChild(IonContent) content: IonContent;
    public manual;
    public contactPerson = null;
    public isDataLoaded = false;
    public chapterListActive = false;
    public coloredToolbar = false;
    public showToolbarTitle = false;
    public toolbarOpacity = "";
    public ionButton = "";
    public search = {
        visible: false,
        total: 0,
        current: 0,
        hilitor: null
    };
    private readonly manualId;
    private readonly userId;

    constructor(
        private platform: Platform,
        private authService: AuthService,
        private manualsService: ManualsService,
        private route: ActivatedRoute,
        private navController: NavController,
        public events: Events
    ) {
        this.manualId = parseInt(this.route.snapshot.params.id, 0);
        this.userId = this.authService.authState.getValue().authUser.id;

        this.listenForWysiwygLinks();
    }

    ionViewWillEnter() {
        this.loadData();
    }

    ionViewWillLeave() {
        this.events.publish("nav:show");
    }

    getChapters() {
        return this.manual.chapters.filter(
            (item) =>
                item.translations.length &&
                item.translations[0].content.length > 0
        );
    }

    getParagraphs(chapter: any) {
        if (typeof chapter.chapters !== "undefined") {
            return chapter.chapters.filter(
                (item) =>
                    item.translations.length && item.translations[0].content
            );
        }

        return null;
    }

    loadData() {
        this.manualsService
            .fetchManualChapters(this.manualId)
            .subscribe((data) => {
                this.manual = data;
                this.isDataLoaded = true;

                if (!!this.manual.contact_person) {
                    this.contactPerson =
                        Object.keys(this.manual.contact_person).length === 0 &&
                        this.manual.contact_person.constructor === Object
                            ? null
                            : this.manual.contact_person;
                }

                if (this.route.snapshot.queryParams.scrollTo) {
                    setTimeout(() => {
                        const scrollToEl = document.getElementById(
                            this.route.snapshot.queryParams.scrollTo
                        );
                        if (scrollToEl) {
                            scrollToEl.scrollIntoView({ behavior: "smooth" });
                        }
                    }, 300);
                }
            });
    }

    authorDetails(author) {
        this.navController.navigateForward([`/authors/${author.id}`]);
    }

    goBack() {
        this.navController.pop();
    }

    get getPublishDate() {
        if (!this.manual.accept_before) {
            return null;
        }
        moment.locale("nl");
        return moment(this.manual.accept_before).format("dddd D MMM YYYY");
    }

    gotoChanges() {
        this.navController.navigateForward([
            `/manuals/${this.manual.manual_id}/changes`
        ]);
    }

    hasNewVersion() {
        return !this.manual.user_accepted_manual_versions.length;
    }

    scrollToChapter(clickedEl, i, includeIndex = false) {
        const id = "chapter_" + i;
        const el = document.getElementById(id);
        el.scrollIntoView({ behavior: "smooth" });
        this.chapterListActive = false;

        // Handle list elements "active" class
        const chapterList = clickedEl.closest(".chapters-list");

        for (const li of chapterList.getElementsByTagName("li")) {
            li.classList.remove("active");
        }

        clickedEl.classList.add("active");

        if (includeIndex) {
            document
                .getElementById("index_chapter_" + i)
                .scrollIntoView({ behavior: "smooth" });
        }
    }

    listenForWysiwygLinks() {
        document.addEventListener("click", (e) => {
            const el = e.target as HTMLAnchorElement;
            const hasWysiwyg = el.closest(".wysiwyg");
            const isLink = el.nodeName.toUpperCase() === "A";

            if (isLink && hasWysiwyg) {
                e.preventDefault();
                window.open(el.href, "_blank");
            }
        });
    }

    onScroll($event) {
        if ($event && $event.detail && $event.detail.scrollTop) {
            const scrollTop = $event.detail.scrollTop;
            this.toolbarOpacity = `hsla(0, 0%, 100%, ${scrollTop / 255})`;
            this.ionButton = `hsl(0, 0%, ${100 - scrollTop}%)`;
            this.showToolbarTitle = true;
            this.coloredToolbar = scrollTop >= 225;
        } else {
            this.toolbarOpacity = `hsla(0, 0%, 100%, 0)`;
            this.ionButton = `hsl(0, 0%, 100%)`;
            this.coloredToolbar = false;
            this.showToolbarTitle = false;
        }
    }

    showSearchBox() {
        this.search.visible = true;
        this.searchInput.setFocus();
        this.events.publish("nav:hide");
    }

    onSearch(e) {
        if (!this.search.hilitor) {
            this.search.hilitor = new Hilitor({
                hClass: "searched-text",
                tag: "SPAN"
            });
        }

        const val = (e.target as HTMLInputElement).value.trim();
        const searchableElements =
            document.querySelectorAll('*[id^="chapter_"]');
        if (!val.length) {
            this.search.hilitor.remove();
            return;
        }

        this.search.hilitor.apply(val, searchableElements);

        const found = document.querySelectorAll("span.searched-text");
        this.search.total = found.length;
        this.search.current = found.length ? 1 : 0;

        this.scrollToSearchResult();
    }

    scrollToSearchResult() {
        const found = document.querySelectorAll("span.searched-text");
        found.forEach((e) => e.classList.remove("searched-text--current"));
        const el = found[this.search.current - 1];
        el.classList.add("searched-text--current");

        this.content.getScrollElement().then((scrollEl) => {
            const v = scrollEl.scrollTop + el.getBoundingClientRect().top;
            this.content.scrollToPoint(0, v - this.platform.height() / 2);
        });

        this.searchInput.setFocus();
    }

    closeSearch() {
        const found = document.querySelectorAll("span.searched-text");
        found.forEach((e) =>
            e.classList.remove("searched-text", "searched-text--current")
        );

        this.search.visible = false;
        this.searchInput.value = "";
        this.search.total = 0;
        this.search.current = 0;
        this.events.publish("nav:show");
    }

    showSearchResult(increment) {
        this.search.current = this.search.current + increment;

        if (this.search.current > this.search.total) {
            this.search.current = 1;
        }

        if (this.search.current < 1) {
            this.search.current = this.search.total;
        }

        this.scrollToSearchResult();
    }
}
