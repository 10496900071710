<ion-header *ngIf="isPinVerified">
    <ion-toolbar>
        <ion-buttons slot="start">
            <ion-back-button
                class="black"
                defaultHref="/"
                text=""
            ></ion-back-button>
        </ion-buttons>
        <!--ToDo: This needs to be variable, the subject needs to be the title-->
        <ion-title *ngIf="!documentType.showDocumentName">
            {{ (documentType.title | translate) | ucFirst }}
        </ion-title>
        <ion-title
            *ngIf="documentType.showDocumentName && documentName !== null"
        >
            {{ documentName | ucFirst }}
        </ion-title>

        <!-- Header for mobile -->
        <ion-buttons slot="primary">
            <ion-button (click)="showPDFActionsMenuSlide()" class="black">
                <ion-icon
                    src="assets/icon/three-dots-horizontal.svg"
                    slot="icon-only"
                ></ion-icon>
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
    <ion-toolbar *ngIf="searchActive">
        <div class="searchbar">
            <div class="searchbar-input">
                <ion-searchbar
                    (ionInput)="search()"
                    (ionCancel)="closeSearch()"
                    [(ngModel)]="searchString"
                    [clearIcon]="false"
                    [showCancelButton]="'never'"
                    placeholder="{{ 'news-search.title' | translate }}"
                ></ion-searchbar>
                <span class="searchbar-count">
                    {{ currentHighlight }}/{{ totalHighlights }}
                </span>
            </div>
            <div class="searchbar-button" (click)="nextMatch()">
                <ion-icon
                    src="assets/icon/chevron-down.svg"
                    slot="icon-only"
                ></ion-icon>
            </div>
            <div class="searchbar-button" (click)="prevMatch()">
                <ion-icon
                    src="assets/icon/chevron-up.svg"
                    slot="icon-only"
                ></ion-icon>
            </div>
            <div class="searchbar-button" (click)="closeSearch()">
                <ion-icon
                    src="assets/icon/close.svg"
                    slot="icon-only"
                ></ion-icon>
            </div>
        </div>
    </ion-toolbar>
</ion-header>

<ion-content
    class="no-scroll"
    [ngClass]="{'accode-document-container': hasToAccept}"
>
    <pin-verification
        (pinEntered)="onPinEntered($event)"
        *ngIf=" ! isPinVerified"
    ></pin-verification>

    <ng-template [ngIf]="isPinVerified">
        <div class="icon-container">
            <div class="zoom-container">
                <span (click)="zoomIn()">
                    <ion-icon name="add"></ion-icon>
                </span>

                <span (click)="zoomOut()">
                    <ion-icon name="remove"></ion-icon>
                </span>
                <span (click)="resetZoom()" class="zoom-reset">
                    <ion-icon name="refresh"></ion-icon>
                </span>
            </div>
        </div>

        <div class="wrapper-div">
            <div class="desktop-box-wrap">
                <!-- dropdown menu trigger for desktop-->
                <ion-button
                    fill="clear"
                    (click)="toggleMenu()"
                    class="usermenu"
                >
                    <ion-icon
                        src="assets/icon/three-dots-horizontal.svg"
                        slot="icon-only"
                    ></ion-icon>
                </ion-button>

                <div
                    class="invisible-overlay"
                    (click)="toggleMenu()"
                    [class.active]="!!activeUserMenu"
                ></div>
                <div
                    [class.active]="!!activeUserMenu"
                    class="dropdown-usermenu"
                >
                    <a
                        [href]="attachmentUrl"
                        lines="full"
                        target="_blank"
                        *ngIf="documentType.canDownload"
                    >
                        {{ 'file-preview.download' | translate: { type:
                        (documentType.title | translate) | ucFirst } }}
                    </a>

                    <a
                        (click)="showEmailDesktop()"
                        lines="full"
                        *ngIf="documentType.canMail"
                    >
                        {{ 'file-preview.mail' | translate: { type:
                        (documentType.title | translate) | ucFirst } }}
                    </a>

                    <a (click)="openSearch('desktop')" lines="full">
                        {{ 'news-search.title' | translate }}
                    </a>
                </div>
                <ion-back-button
                    icon="null"
                    class="arrow-back"
                    defaultHref="/"
                ></ion-back-button>
                <h2 *ngIf="!documentType.showDocumentName">
                    {{ (documentType.title | translate) | ucFirst }}
                </h2>
                <h2
                    *ngIf="documentType.showDocumentName && documentName !== null"
                >
                    {{ documentName | ucFirst }}
                </h2>
                <div id="pdfContent">
                    <pinch-zoom *ngIf="isMobile()">
                        <pdf-viewer
                            [autoresize]="true"
                            [fit-to-page]="true"
                            [render-text]="true"
                            [src]="attachmentUrl"
                            [zoom]="pdfZoom"
                            id="pdf-report-id"
                            (text-layer-rendered)="textLayerRendered($event)"
                            #pdfViewer
                        ></pdf-viewer>
                    </pinch-zoom>
                    <pdf-viewer
                        [autoresize]="true"
                        [fit-to-page]="true"
                        [render-text]="true"
                        [src]="attachmentUrl"
                        [zoom]="pdfZoom"
                        id="pdf-report-id"
                        (text-layer-rendered)="textLayerRendered($event)"
                        #pdfViewer
                        #desktop
                        *ngIf="!isMobile()"
                    ></pdf-viewer>
                </div>
            </div>

            <div
                class="accode-document-button-container"
                *ngIf="hasToAccept === true"
            >
                <div
                    *ngIf="isAcceptedByUser === true"
                    class="accode-document-done"
                >
                    <svg
                        width="21"
                        height="14"
                        viewBox="0 0 21 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M12 4H0V6H12V4ZM12 0H0V2H12V0ZM0 10H8V8H0V10ZM19.5 5.5L21 7L14.01 14L9.5 9.5L11 8L14.01 11L19.5 5.5Z"
                            fill="#96B522"
                        />
                    </svg>
                    {{ 'accoding-documents.document-accoded' | translate }}
                </div>
                <ion-button
                    *ngIf="isAcceptedByUser === false"
                    (click)="openToggleAccodeModal()"
                    class="accode-document-btn"
                    expand="full"
                >
                    <svg
                        viewBox="0 0 22 19"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M0 19H22L11 0L0 19ZM12 16H10V14H12V16ZM12 12H10V8H12V12Z"
                            fill="white"
                        />
                    </svg>
                    {{ 'accoding-documents.button-content' | translate }}
                </ion-button>
            </div>
        </div>
    </ng-template>
</ion-content>

<!-- This is the menu for mobile -->
<app-menu-slider
    *ngIf="documentType.canDownload || documentType.canMail"
    #pdfActionsMenuSlider
    [closeOnClick]="false"
    withoutPadding="true"
>
    <ion-list *ngIf="!emailPdf">
        <ion-item
            (click)="showEmail()"
            lines="full"
            *ngIf="documentType.canMail"
        >
            <ion-label>
                {{ 'file-preview.mail' | translate: { type: (documentType.title
                | translate) | ucFirst } }}
            </ion-label>
        </ion-item>

        <ion-item
            [href]="attachmentUrl"
            lines="full"
            target="_blank"
            *ngIf="documentType.canDownload"
        >
            <ion-label>
                {{ 'file-preview.download' | translate: { type:
                (documentType.title | translate) | ucFirst } }}
            </ion-label>
        </ion-item>

        <ion-item lines="full" (click)="openSearch('mobile')">
            <ion-label>{{ 'news-search.title' | translate }}</ion-label>
        </ion-item>
    </ion-list>

    <ng-template [ngIf]="emailPdf">
        <div class="content-wrap">
            <h3>
                {{ 'file-preview.mail-title' | translate: { type:
                documentType.title | translate | ucFirst } }}
            </h3>

            <p>
                {{ 'file-preview.mail-description' | translate: { type:
                documentType.title | translate } }}
            </p>

            <ion-input
                [(ngModel)]="email"
                #emailInput
                autofocus="true"
                placeholder="{{ 'file-preview.email' | translate }}"
            ></ion-input>

            <div (click)="emailAttachment()" *ngIf="! sendingMail" class="btn">
                {{ 'file-preview.send-email' | translate }}
            </div>
            <ion-spinner *ngIf="sendingMail"></ion-spinner>
        </div>
    </ng-template>
</app-menu-slider>
