import {
    APP_INITIALIZER,
    NgModule,
    CUSTOM_ELEMENTS_SCHEMA,
    ErrorHandler
} from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { Router, RouteReuseStrategy } from "@angular/router";

import { IonicModule, IonicRouteStrategy } from "@ionic/angular";
import { StatusBar } from "@ionic-native/status-bar/ngx";

import { Storage, IonicStorageModule } from "@ionic/storage";
import { JwtModule, JWT_OPTIONS } from "@auth0/angular-jwt";
import { HTTP_INTERCEPTORS, HttpClient } from "@angular/common/http";

import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";
import { HttpClientModule } from "@angular/common/http";
import { MasterPageModule } from "./views/master/master.module";
import { NewsPageModule } from "./views/news/news.module";
import { DocumentsPageModule } from "./views/documents/documents.module";
import { DocumentsDetailPageModule } from "./views/documents-detail/documents-detail.module";
import { NewsDetailPageModule } from "./views/news-detail/news-detail.module";
import { AuthorsPageModule } from "./views/authors/authors.module";
import { ManualListPageModule } from "./views/manual-list/manual-list.module";
import { ManualPageModule } from "./views/manual/manual.module";
import { ManualChangesPageModule } from "./views/manual-changes/manual-changes.module";
import { RefreshTokenInterceptor } from "./interceptors/refresh-token-interceptor";
import { AuthService, authStateKey } from "./services/auth/auth.service";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { PdfViewerModule } from "ng2-pdf-viewer";
import { PushNotificationsPageModule } from "./views/push-notifications/push-notifications.module";
import { AppendAppVersionInterceptor } from "./interceptors/append-app-version-interceptor";
import { DocumentsSearchModule } from "./views/documents-search/documents-search.module";
import { FilePreviewModule } from "./views/file-preview/file-preview.module";
import { TermsAndConditionsPageModule } from "./views/terms-and-conditions/terms-and-conditions.module";
import { PrivacyPolicyPageModule } from "./views/privacy-policy/privacy-policy.module";
import { AccodingHandbooksComponent } from "./components/accoding-handbooks/accoding-handbooks.component";
import { FavoritePageModule } from "./views/news/favorite/favorite.module";
import { WorkClothingPageModule } from "./views/profile/work-clothing/work-clothing.module";
import { DocumentNavigationModalComponent } from "./components/document-navigation-modal/document-navigation-modal.component";
import { AttendModalComponent } from "./components/attend-modal/attend-modal.component";
import { AccodeDocumentModalComponent } from "./components/accode-document-modal/accode-document-modal.component";
import { PublicPrivacyPolicyPageModule } from "./views/public/privacy-policy/public-privacy-policy.module";
import * as Sentry from "@sentry/capacitor";
import {
    init as sentryAngularInit,
    createErrorHandler,
    TraceService
} from "@sentry/angular";
import packageJson from "../../package.json";
import { ServerErrorInterceptor } from "./interceptors/server-error-interceptor";

export function jwtOptionsFactory(storage) {
    return {
        tokenGetter: async () => {
            const authState = await storage.get(authStateKey);
            return authState && authState.token ? authState.token : null;
        },
        whitelistedDomains: [/.*/]
    };
}

export function authServiceFactory(provider: AuthService) {
    return () => provider.setAuthState();
}

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}

const version = packageJson.version;
Sentry.init(
    {
        dsn: "https://58fd9803963cf8e44c699c824002c952@o349358.ingest.us.sentry.io/4507764059734016",
        release: `AB-Mobile@${version}`,
        integrations: [
            // Registers and configures the Tracing integration,
            // which automatically instruments your application to monitor its
            // performance, including custom Angular routing instrumentation
            Sentry.browserTracingIntegration(),
            // Registers the Replay integration,
            // which automatically captures Session Replays
            Sentry.replayIntegration()
        ],

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for tracing.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,

        // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [
            "localhost",
            /^https:\/\/admin\.ab-connect\.nl\/api/
        ],

        // Capture Replay for 10% of all sessions,
        // plus for 100% of sessions with an error
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0
    },
    sentryAngularInit
);

@NgModule({
    declarations: [
        AppComponent,
        AccodingHandbooksComponent,
        DocumentNavigationModalComponent,
        AttendModalComponent,
        AccodeDocumentModalComponent
    ],
    imports: [
        BrowserModule,
        IonicModule.forRoot({
            innerHTMLTemplatesEnabled: true
        }),
        IonicStorageModule.forRoot(),
        AppRoutingModule,
        HttpClientModule,
        MasterPageModule,
        NewsPageModule,
        FilePreviewModule,
        DocumentsPageModule,
        DocumentsDetailPageModule,
        DocumentsSearchModule,
        NewsDetailPageModule,
        PdfViewerModule,
        AuthorsPageModule,
        ManualListPageModule,
        ManualPageModule,
        ManualChangesPageModule,
        PushNotificationsPageModule,
        TermsAndConditionsPageModule,
        PrivacyPolicyPageModule,
        PublicPrivacyPolicyPageModule,
        FavoritePageModule,
        WorkClothingPageModule,
        JwtModule.forRoot({
            jwtOptionsProvider: {
                provide: JWT_OPTIONS,
                useFactory: jwtOptionsFactory,
                deps: [Storage]
            }
        }),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        })
    ],
    providers: [
        StatusBar,
        {
            provide: APP_INITIALIZER,
            useFactory: authServiceFactory,
            deps: [AuthService, Storage],
            multi: true
        },
        {
            provide: ErrorHandler,
            useValue: createErrorHandler()
        },
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: RefreshTokenInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AppendAppVersionInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ServerErrorInterceptor,
            multi: true
        },
        {
            provide: TraceService,
            deps: [Router]
        },
        {
            provide: APP_INITIALIZER,
            useFactory: () => () => {},
            deps: [TraceService],
            multi: true
        }
    ],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {}
