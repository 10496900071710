import { Component } from "@angular/core";
import { TermsService } from "../../services/terms/terms.service";
import { NavController } from "@ionic/angular";

@Component({
    selector: "app-terms-and-conditions",
    templateUrl: "./terms-and-conditions.page.html",
    styleUrls: ["./terms-and-conditions.page.scss"]
})
export class TermsAndConditionsPage {
    constructor(
        private termsService: TermsService,
        private navController: NavController
    ) {}

    acceptTerms() {
        this.termsService.acceptTerms().subscribe((then) => {
            this.navController.navigateRoot(["/"]);
        });
    }
}
