import { Injectable } from "@angular/core";
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpErrorResponse
} from "@angular/common/http";
import { AuthService } from "../services/auth/auth.service";
import { BehaviorSubject, Observable, of, throwError } from "rxjs";
import { catchError, filter, switchMap, take } from "rxjs/operators";
import { NavController } from "@ionic/angular";

@Injectable()
export class RefreshTokenInterceptor implements HttpInterceptor {
    private isRefreshing = false;
    private refreshTokenSubject: BehaviorSubject<any> =
        new BehaviorSubject<any>(null);

    constructor(
        public authService: AuthService,
        public navController: NavController
    ) {}

    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        if (request.url.endsWith("v2/auth/login")) {
            return next.handle(this.addTwoFaToken(request));
        }

        return next.handle(request).pipe(
            catchError((error: HttpErrorResponse) => {
                if (request.url.endsWith("refresh-token")) {
                    this.authService.logout();
                    this.isRefreshing = false;

                    return throwError(error);
                }

                if (request.url.endsWith("logout")) {
                    this.isRefreshing = false;

                    return throwError(error);
                }

                if (
                    error.status === 403 &&
                    error.error.message === "token-type-not-present"
                ) {
                    this.authService.completeLogout();
                    this.isRefreshing = false;

                    return throwError(error);
                }

                if (![401, 455, 456].includes(error.status)) {
                    return throwError(error);
                }

                if (error.status === 455) {
                    this.navController.navigateRoot("/profile");
                    return throwError(error);
                }

                if (error.status === 456) {
                    this.authService.completeLogout();
                    return throwError(error);
                }

                if (this.isRefreshing) {
                    return this.refreshTokenSubject.pipe(
                        filter((r) => r !== null),
                        take(1),
                        switchMap(() => next.handle(this.addToken(request)))
                    );
                } else {
                    this.isRefreshing = true;
                    this.refreshTokenSubject.next(null);

                    return this.authService.refreshToken().pipe(
                        switchMap((data: any) => {
                            this.isRefreshing = false;
                            this.refreshTokenSubject.next(data.token);

                            return next.handle(this.addToken(request));
                        })
                    );
                }
            })
        );
    }

    addToken(request) {
        const accessToken = this.authService.authState.getValue().token;

        if (!accessToken) {
            return request;
        }

        return request.clone({
            setHeaders: {
                Authorization: `Bearer ${accessToken}`
            }
        });
    }

    addTwoFaToken(request: HttpRequest<any>) {
        const accessToken =
            this.authService.authState.getValue().twoFactorToken;

        if (!accessToken) {
            return request;
        }

        return request.clone({
            setHeaders: {
                Authorization: `Bearer ${accessToken}`
            }
        });
    }
}
