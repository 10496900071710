<ion-router-outlet>
    <app-navbar (menuStateChange)="onMenuStateChange()"></app-navbar>
    <app-menu-slider
        #mainMenu
        title="Menu"
        withoutPadding="true"
        (menuSliderClosed)="onMenuClosed()"
    >
        <ion-list>
            <ion-item
                *ngFor="let item of this.baseMenuItems"
                lines="full"
                routerLink="/{{item.routerLink}}"
            >
                <ion-label>{{item.name}}</ion-label>
            </ion-item>

            <ion-item
                *ngFor="let item of this.menuItems"
                lines="full"
                routerLink="/{{item.routerLink}}"
            >
                <ion-label>{{item.name}}</ion-label>
            </ion-item>
        </ion-list>
    </app-menu-slider>
</ion-router-outlet>
