<ion-header class="ion-padding-horizontal blue" no-border>
    <ion-toolbar class="ion-no-padding">
        <profile-image [usePng]="true"></profile-image>
        <ion-title>
            <img alt="ab-logo" src="assets/images/ab.svg" />
        </ion-title>
        <ion-searchbar
            debounce="500"
            placeholder="{{ 'news-search.title' | translate }}"
            routerLink="/news/search"
        ></ion-searchbar>
        <img
            alt="Zoeken"
            class="search-icon"
            routerLink="/news/search"
            src="assets/images/icons/search-white.svg"
        />
    </ion-toolbar>
</ion-header>

<ion-content (ionScroll)="onScroll($event)" [scrollEvents]="true">
    <ion-refresher
        class="refresher"
        (ionRefresh)="doRefresh($event)"
        slot="fixed"
    >
        <ion-refresher-content></ion-refresher-content>
    </ion-refresher>

    <div class="blue-container-wrapper">
        <div
            class="blue-container"
            id="blue-header-background"
            [style.height.%]="heightPercentage * 1.1"
        ></div>
    </div>

    <div class="page">
        <div class="wrapper-div">
            <div class="content-wrap article-list">
                <div class="ride-info" *ngIf="showRideInfo">
                    <div class="head">
                        <img src="/assets/images/wave_icon.svg" />
                        <div class="title-wrap">
                            <span class="title">Goedemorgen Mous!</span>
                            <span class="subtitle">Eerste rit van vandaag</span>
                        </div>
                    </div>
                    <div class="timeline">
                        <div class="item">
                            <img
                                class="dot"
                                src="/assets/images/blue_dot.svg"
                            />
                            <span class="title">AB Texel Group</span>
                            <span class="row">Truck: 68-BNV-6</span>
                            <span class="row">Trailer: OK-29-NJ</span>
                        </div>
                        <div class="item">
                            <img
                                class="dot"
                                src="/assets/images/blue_dot.svg"
                            />
                            <span class="title">Laden in Sneek</span>
                            <span class="row">Vandaag om 10:08</span>
                            <span class="row">De Heus Voeders B.V.</span>
                            <span class="row">
                                Einsteinstraat 17, Sneek · 8606 JR
                            </span>
                        </div>
                        <div class="item">
                            <img
                                class="dot"
                                src="/assets/images/blue_dot.svg"
                            />
                            <span class="title">Lossen in Portugal</span>
                            <span class="row">Vrijdag om 06:43</span>
                            <span class="row">A.H.M. Schuttenbeld</span>
                            <span class="row">
                                R. Inácio Perdigão 7, Caldas da Rainha, Portugal
                                · 2500-303
                            </span>
                        </div>
                    </div>
                    <div class="bottom">
                        <img
                            class="dot"
                            src="/assets/images/glasses_icon.svg"
                        />
                        <!--                        <a href="#">Bekijk alle ritten</a>-->
                    </div>
                </div>
                <div
                    *ngFor="let article of articles | slice:0:2;let i = index;"
                    [class.story]="article.story_profile"
                    class="article-wrap"
                >
                    <favorite-news-article
                        [articleId]="article.id"
                        [isFavorite]="article.is_favorite"
                    ></favorite-news-article>
                    <div (click)="newsDetail(article)">
                        <div
                            (click)="authorDetails($event, article.story_profile)"
                            *ngIf="article.story_profile"
                            class="story-of"
                        >
                            <div class="avatar">
                                <img
                                    [src]="loadContactSecureImage(article.story_profile)"
                                />
                            </div>
                            <h3>
                                {{ 'news.story-of' | translate }} {{
                                article.story_profile.title }}
                                <span class="green">.</span>
                            </h3>
                        </div>
                        <div class="article">
                            <img
                                *ngIf="article.has_feature_image"
                                [src]="loadArticleSecureImage(article)"
                                alt="{{article.title}}"
                            />
                            <span class="readtime">
                                {{article.reading_time}} {{ 'news.minutes-read'
                                | translate }}
                            </span>
                            <h4>{{article.translations[0].content}}</h4>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="tips.length > 0" class="vertical-articles">
                <div class="content-wrap slider-content-wrap">
                    <h3>
                        {{ 'news.good-to-know.title' | translate }}
                        <span class="green">.</span>
                    </h3>
                    <span class="description">
                        {{ 'news.good-to-know.description' | translate }}
                    </span>
                    <div class="arrows">
                        <div
                            (click)="previousImage()"
                            class="slide-nav prev-slide"
                        >
                            <img
                                alt="Previous slide"
                                src="assets/images/icons/arrow.svg"
                            />
                        </div>
                        <div (click)="nextImage()" class="slide-nav next-slide">
                            <img
                                alt="Next slide"
                                src="assets/images/icons/arrow.svg"
                            />
                        </div>
                    </div>
                </div>

                <div class="image-slider">
                    <div [ngStyle]="imageTranslation()" class="news-items">
                        <a
                            (click)="newsDetail(tip)"
                            *ngFor="let tip of tips; index as i"
                            [class.passed]="currentSlide > i + 1"
                            class="item slider-item"
                        >
                            <div
                                (click)="authorDetails($event, tip.story_profile)"
                                *ngIf="tip.story_profile"
                                class="story-of"
                            >
                                <div class="avatar">
                                    <img
                                        [src]="loadContactSecureImage(tip.story_profile)"
                                    />
                                </div>
                                <h3>
                                    Verhaal van {{ tip.story_profile.title }}
                                    <span class="green">.</span>
                                </h3>
                            </div>
                            <div class="article">
                                <img
                                    *ngIf="tip.has_feature_image"
                                    [src]="loadArticleSecureImage(tip)"
                                    alt="{{tip.title}}"
                                />
                                <span class="readtime">
                                    {{tip.reading_time}} min lezen
                                </span>
                                <h4>{{tip.translations[0].content}}</h4>
                            </div>
                        </a>
                    </div>
                </div>
            </div>

            <div class="content-wrap article-list">
                <div
                    *ngFor="let article of articles | slice:2;let i = index;"
                    [class.story]="article.story_profile"
                    class="article-wrap"
                >
                    <favorite-news-article
                        [articleId]="article.id"
                        [isFavorite]="article.is_favorite"
                    ></favorite-news-article>
                    <div (click)="newsDetail(article)">
                        <div
                            (click)="authorDetails($event, article.story_profile)"
                            *ngIf="article.story_profile"
                            class="story-of"
                        >
                            <div class="avatar">
                                <img
                                    [src]="loadContactSecureImage(article.story_profile)"
                                />
                            </div>
                            <h3>
                                Verhaal van {{ article.story_profile.title }}
                                <span class="green">.</span>
                            </h3>
                        </div>
                        <div class="article">
                            <img
                                *ngIf="article.has_feature_image"
                                [src]="loadArticleSecureImage(article)"
                                alt="{{article.title}}"
                            />
                            <span class="readtime">
                                {{article.reading_time}} min lezen
                            </span>
                            <h4>{{article.translations[0].content}}</h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <ion-infinite-scroll (ionInfinite)="loadData($event)" threshold="400px">
            <ion-infinite-scroll-content
                loadingSpinner="bubbles"
                loadingText="Volgende nieuwsberichten ophalen.."
            ></ion-infinite-scroll-content>
        </ion-infinite-scroll>
    </div>
</ion-content>
