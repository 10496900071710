import { Injectable } from "@angular/core";
import { BaseHttpService } from "../base-http.service";
import { HttpClient } from "@angular/common/http";

@Injectable({
    providedIn: "root"
})
export class ColleaguesService extends BaseHttpService {
    constructor(private http: HttpClient) {
        super();
    }

    fetchAnniversaries() {
        return this.http.get<any>(`${this.baseUrl}/colleagues/anniversaries`);
    }

    fetchEmployments() {
        return this.http.get<any>(`${this.baseUrl}/colleagues/employments`);
    }

    fetchUnemployments() {
        return this.http.get<any>(`${this.baseUrl}/colleagues/unemployment`);
    }
}
