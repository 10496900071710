import { Component, ViewChild } from "@angular/core";
import { NewsService } from "../../services/news/news.service";
import { BehaviorSubject } from "rxjs";
import { AuthService } from "../../services/auth/auth.service";
import { IonSearchbar, NavController } from "@ionic/angular";
import { DocumentModel } from "../../entities/DocumentModel";
import { DocumentsService } from "../../services/documents/documents.service";
import { BrowserService } from "../../services/browser/browser.service";

@Component({
    selector: "app-documents-search",
    templateUrl: "./documents-search.page.html",
    styleUrls: ["./documents-search.page.scss"]
})
export class DocumentsSearchPage {
    @ViewChild("search") search: IonSearchbar;

    // eslint-disable-next-line @typescript-eslint/ban-types
    public searchDocuments = Array<DocumentModel>();
    public isLoading = false;
    private currentPage = 1;
    fetched = new BehaviorSubject(false);

    constructor(
        private authService: AuthService,
        private newsService: NewsService,
        private navController: NavController,
        private documentService: DocumentsService,
        private browserService: BrowserService
    ) {}

    ionViewWillEnter() {
        this.search.setFocus();

        this.currentPage = 1;
        this.fetchDocuments();
    }

    loadData(event) {
        this.isLoading = true;

        setTimeout(() => {
            this.fetched.subscribe((fetched) => {
                if (fetched) {
                    event.target.complete();
                    this.fetched.next(false);
                }
            });
        }, 1000);
    }

    authorDetails($event, author) {
        $event.stopPropagation();
        this.navController.navigateForward([`/authors/${author.id}`]);
    }

    fetchDocuments() {
        this.searchDocuments = Array<DocumentModel>();

        this.isLoading = true;

        this.documentService
            .searchDocuments(this.search.value)
            .subscribe((data) => {
                data.forEach((item) => {
                    this.searchDocuments.push(new DocumentModel(item));
                });

                this.fetched.next(true);
                this.currentPage++;
                this.isLoading = false;
            });
    }

    searchChange(): void {
        this.fetchDocuments();
        // eslint-disable-next-line @typescript-eslint/ban-types
        this.currentPage = 1;
    }

    openLink(document: DocumentModel) {
        if (document.type === "map") {
            this.navController.navigateForward("/documents/" + document.id);
        } else if (document.type === "doc") {
            if (document.docType === "document") {
                this.navController.navigateForward("/preview/4/" + document.id);
            } else if (document.docType === "url") {
                this.browserService.openLink(document.url);
            }
        }
    }
}
