import { AuthUser } from "./AuthUser";

export class AuthState {
    authenticated: boolean;
    pin: string;
    isPinSet: boolean;
    token: string;
    twoFactorToken: string;
    phone: string;
    authUser: AuthUser;
    authChanged: boolean;
    tokenExpiration: number;
    refreshTokenExpiration: number;
    deviceAppToken: string;

    constructor(
        authenticated: boolean,
        isPinSet: boolean,
        token: string,
        twoFactorToken: string,
        phone: string,
        authUser: AuthUser,
        tokenExpiration: number,
        refreshTokenExpiration: number,
        authChanged: boolean = false
    ) {
        this.authenticated = authenticated;
        this.pin = null;
        this.isPinSet = isPinSet;
        this.token = token;
        this.twoFactorToken = twoFactorToken;
        this.phone = phone;
        this.authUser = authUser;
        this.authChanged = authChanged;
        this.tokenExpiration = tokenExpiration;
        this.refreshTokenExpiration = refreshTokenExpiration;
        this.deviceAppToken = AuthState.generateRandomToken();
    }

    static makeEmpty() {
        return new AuthState(false, false, null, null, null, null, 0, 0);
    }

    static makeFromObject(obj) {
        return new AuthState(
            obj.authenticated || false,
            obj.isPinSet || false,
            obj.token || null,
            obj.twoFactorToken || null,
            obj.phone || null,
            obj.authUser ? new AuthUser(obj.authUser) : null,
            obj.tokenExpiration || 0,
            obj.refreshTokenExpiration || 0,
            true
        );
    }

    static generateRandomToken() {
        return (
            Math.random().toString(36).substring(2, 15) +
            Math.random().toString(36).substring(2, 15)
        );
    }

    setAuth(state: boolean) {
        this.authenticated = state;
        this.authChanged = true;
    }

    setPhone(phone: string) {
        this.phone = phone;
        this.authChanged = true;
    }

    setToken(token: string) {
        this.token = token;
        this.authChanged = true;
    }

    setTwoFactorToken(token: string) {
        this.twoFactorToken = token;
        this.authChanged = true;
    }

    setPinState(state: boolean) {
        this.isPinSet = state;
        this.authChanged = true;
    }

    setPin(pin: string) {
        this.pin = pin;
        this.authChanged = true;
    }

    setAuthUserState(authUser: AuthUser) {
        this.authUser = authUser;
    }

    setTokenExpiration(timestamp: number) {
        this.tokenExpiration = timestamp;
    }

    setRefreshTokenExpiration(timestamp: number) {
        this.refreshTokenExpiration = timestamp;
    }

    isTokenExpired() {
        const now = Math.floor(Date.now() / 1000);

        return now > this.tokenExpiration;
    }

    isRefreshTokenExpired() {
        const now = Math.floor(Date.now() / 1000);

        return now > this.refreshTokenExpiration;
    }

    hasTwoFactorToken() {
        return !!this.twoFactorToken;
    }

    hasPhone() {
        return !!this.phone;
    }

    hasSetPin() {
        return this.isPinSet;
    }

    isAuthenticated() {
        return this.authenticated;
    }

    isChanged() {
        return this.authChanged;
    }
}
