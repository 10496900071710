<ion-header no-border>
    <ion-toolbar class="ion-padding-horizontal">
        <profile-image [usePng]="true"></profile-image>
        <ion-title>{{ 'news.favorite-articles' | translate }}</ion-title>
    </ion-toolbar>
</ion-header>

<ion-content [scrollEvents]="true" (ionScroll)="onScroll($event)">
    <ion-refresher (ionRefresh)="doRefresh($event)" slot="fixed">
        <ion-refresher-content></ion-refresher-content>
    </ion-refresher>

    <div class="page">
        <div class="wrapper-div">
            <h1>{{ 'news.favorite-articles' | translate }}</h1>
            <span class="description">
                {{ 'news.favorite-articles-description' | translate }}
            </span>

            <div class="content-wrap article-list">
                <div
                    *ngFor="let article of articles"
                    [class.story]="article.story_profile"
                    class="article-wrap"
                >
                    <favorite-news-article
                        [isFavorite]="article.is_favorite"
                        [articleId]="article.id"
                    ></favorite-news-article>
                    <div (click)="newsDetail(article)">
                        <div
                            (click)="authorDetails($event, article.story_profile)"
                            *ngIf="article.story_profile"
                            class="story-of"
                        >
                            <div class="avatar">
                                <img
                                    [src]="loadContactSecureImage(article.story_profile)"
                                />
                            </div>
                            <h3>
                                {{ 'news.story-of' | translate }} {{
                                article.story_profile.title }}
                                <span class="green">.</span>
                            </h3>
                        </div>
                        <div class="article">
                            <img
                                *ngIf="article.has_feature_image"
                                [src]="loadArticleSecureImage(article)"
                                alt="{{article.title}}"
                            />
                            <span class="readtime">
                                {{article.reading_time}} {{ 'news.minutes-read'
                                | translate }}
                            </span>
                            <h4>{{article.translations[0].content}}</h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <ion-infinite-scroll (ionInfinite)="loadData($event)" threshold="400px">
            <ion-infinite-scroll-content
                loadingSpinner="bubbles"
                loadingText="Volgende nieuwsberichten ophalen.."
            ></ion-infinite-scroll-content>
        </ion-infinite-scroll>
    </div>
</ion-content>
