import { Component } from "@angular/core";
import { AuthService } from "../../services/auth/auth.service";
import { ManualsService } from "../../services/manuals/manuals.service";
import { version } from "punycode";

@Component({
    selector: "app-manual-list",
    templateUrl: "./manual-list.page.html",
    styleUrls: ["./manual-list.page.scss"]
})
export class ManualListPage {
    public manuals = [];
    public isDataLoaded = false;

    constructor(
        private authService: AuthService,
        private manualsService: ManualsService
    ) {}

    ionViewWillEnter() {
        this.manualsService.fetchList().subscribe((data) => {
            this.manuals = data;
            this.isDataLoaded = true;
        });
    }

    hasNewVersion(manual) {
        let versions = [...manual.versions];

        versions = versions
            .filter((v) => !!v.published_at)
            .sort((a, b) => b.version - a.version);

        if (
            versions.length &&
            versions[0].user_accepted_manual_versions.length
        ) {
            return false;
        }

        return true;
    }
}
