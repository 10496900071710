import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BaseHttpService } from "../base-http.service";

@Injectable({
    providedIn: "root"
})
export class SettingsService extends BaseHttpService {
    constructor(private http: HttpClient) {
        super();
    }

    fetchSettings() {
        return this.http.get<any>(
            `${this.baseUrl}/settings/push-notifications`
        );
    }

    persistNotifications(notifications) {
        return this.http.put<any>(
            `${this.baseUrl}/settings/push-notifications`,
            notifications
        );
    }
}
