<div [class.hide]="!visible" class="fixed-bottom">
    <div class="menu-container">
        <div class="desktop-logo">
            <img routerLink="/news" src="/assets/images/logo-desktop.svg" />
        </div>

        <ng-container *ngFor="let item of this.baseMenuItems">
            <div
                *ngIf="item.routerLink !== null"
                class="icon-container"
                routerDirection="root"
                routerLink="/{{ item.routerLink }}"
                routerLinkActive="active"
                [routerLinkActiveOptions]="item.routerLinkOptions"
            >
                <div class="item">
                    <svg-icon src="/assets/icon/{{ item.icon }}.svg"></svg-icon>
                    <span>{{ item.name }}</span>
                </div>
            </div>

            <div *ngIf="item.routerLink === null" class="icon-container">
                <div class="item" (click)="showDocumentsMenu()">
                    <svg-icon src="/assets/icon/{{ item.icon }}.svg"></svg-icon>
                    <span>{{ "navigation.documents" | translate }}</span>
                </div>
            </div>
        </ng-container>

        <div
            *ngFor="let item of this.menuItems"
            class="icon-container desktop"
            routerDirection="root"
            routerLink="/{{ item.routerLink }}"
            routerLinkActive="active"
            [routerLinkActiveOptions]="item.routerLinkOptions"
        >
            <div class="item">
                <svg-icon src="/assets/icon/{{ item.icon }}.svg"></svg-icon>
                <span>{{ item.name }}</span>
            </div>
        </div>

        <div (click)="toggleMenu()" class="icon-container mobile">
            <div class="item">
                <svg-icon src="/assets/icon/baseline-menu-24px.svg"></svg-icon>
                <span>{{ "navigation.menu" | translate }}</span>
            </div>
        </div>
    </div>
</div>
