import { Component, OnInit } from "@angular/core";
import { Contact } from "../../entities/Contact";
import { ActivatedRoute } from "@angular/router";
import { NavController } from "@ionic/angular";
import { API_URL } from "../../../environments/environment";
import { AuthService } from "../../services/auth/auth.service";
import { ContactsService } from "../../services/contacts/contacts.service";
import { BrowserService } from "../../services/browser/browser.service";
import { Capacitor } from "@capacitor/core";

@Component({
    selector: "app-authors-page",
    templateUrl: "./authors.page.html",
    styleUrls: ["./authors.page.scss"]
})
export class AuthorsPage implements OnInit {
    public contact = new Contact(null);
    public isDataLoaded = false;
    private readonly contactId;

    constructor(
        private authService: AuthService,
        private contactsService: ContactsService,
        private route: ActivatedRoute,
        private navController: NavController
    ) {
        this.contactId = parseInt(this.route.snapshot.params.authorId, 0);
    }

    ngOnInit() {
        this.contactsService.fetchContact(this.contactId).subscribe((data) => {
            this.contact = new Contact(data);
            this.isDataLoaded = true;
        });
    }

    goBack() {
        this.navController.pop();
    }

    call() {
        if (Capacitor.isNativePlatform()) {
            window.open(`tel:${this.contact.phone}`, "_system");
        } else {
            window.location.href = `tel:${this.contact.phone}`;
        }
    }

    mail() {
        if (Capacitor.isNativePlatform()) {
            window.open(`mailto:${this.contact.email}`, "_system");
        } else {
            window.location.href = `mailto:${this.contact.email}`;
        }
    }

    loadContactSecureImage(contact) {
        if (!contact.photo) {
            return "../assets/images/default-account.png";
        }

        const token = this.authService.authState.getValue().token;

        return `${API_URL}/files/contacts/${contact.photo}?token=${token}`;
    }
}
