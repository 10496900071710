export class AuthUser {
    id: number;
    language_id: number;
    employee_function_id: number;
    business_unit_id: number;
    name: string;
    phonenumber: string;
    street: string;
    street_number: string;
    city: string;
    country: string;
    image_path: string;
    is_active: boolean;
    has_valid_deactivation: boolean;
    email: string;
    state_updated_at: Date;
    employment_start_date: string;
    employment_end_date: string;
    end_date_is_retire_date: boolean;
    business_unit_permissions: [any];
    has_accepted_terms: boolean;
    has_manuals_to_accept: boolean;
    can_change_division: boolean;
    can_view_poll_results: boolean;

    constructor(user) {
        if (!user) {
            return;
        }

        this.id = user.id;
        this.language_id = user.language_id;
        this.employee_function_id = user.employee_function_id;
        this.business_unit_id = user.business_unit_id;
        this.name = user.name;
        this.phonenumber = user.phonenumber;
        this.street = user.street;
        this.street_number = user.street_number;
        this.city = user.city;
        this.country = user.country;
        this.image_path = user.image_path;
        this.is_active = user.is_active;
        this.has_valid_deactivation = user.has_valid_deactivation;
        this.email = user.email;
        this.state_updated_at = new Date();
        this.employment_start_date = user.employment_start_date;
        this.employment_end_date = user.employment_end_date;
        this.end_date_is_retire_date = user.end_date_is_retire_date;
        this.business_unit_permissions = user.business_unit_permissions;
        this.has_accepted_terms = user.has_accepted_terms;
        this.has_manuals_to_accept = user.has_manuals_to_accept;
        this.can_change_division = user.can_change_division || false;
        this.can_view_poll_results = user.can_view_poll_results || false;
    }

    canChangeDivision() {
        return this.can_change_division;
    }

    canViewPollResults() {
        return this.can_view_poll_results;
    }

    updatedAtTimestamp() {
        return this.state_updated_at.valueOf();
    }

    /**
     * Helper method to check if user's business unit has permissions.
     */
    hasPermission(permission: string) {
        return (
            this.business_unit_permissions.findIndex(
                (p) => p.name === permission
            ) > -1
        );
    }
}
