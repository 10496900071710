import { Injectable } from "@angular/core";
import { CanActivate } from "@angular/router";
import { AuthService } from "../../services/auth/auth.service";
import { NavController } from "@ionic/angular";

@Injectable({
    providedIn: "root"
})
export class GuestGuard implements CanActivate {
    constructor(
        public authService: AuthService,
        public navController: NavController
    ) {}

    canActivate(): boolean {
        if (this.authService.isAuthenticated()) {
            this.navController.navigateRoot(["/"]);
            return false;
        }

        return true;
    }
}
