import { Component, Input, OnInit } from "@angular/core";
import { API_URL } from "../../../environments/environment";
import { AuthService } from "../../services/auth/auth.service";
import { NavController } from "@ionic/angular";
import { PollService } from "../../services/poll/poll.service";
import { AuthUser } from "../../entities/AuthUser";

@Component({
    selector: "ab-component",
    templateUrl: "./ab-component.component.html",
    styleUrls: ["./ab-component.component.scss"]
})
export class AbComponentComponent implements OnInit {
    @Input() component: any;

    public selectedOption = null;
    public showingPollResults = false;
    private optionVotes: { votes: number; id: number }[];
    public totalVotes: number;
    public myVote = null;
    public profile = null;
    public pollData = {
        answers: []
    };

    constructor(
        private authService: AuthService,
        private navController: NavController,
        private pollService: PollService
    ) {
        this.authService.userProfileState
            .asObservable()
            .subscribe((authUser: AuthUser) => {
                this.profile = authUser;
            });

        this.totalVotes = 0;
        this.optionVotes = [];
    }

    ngOnInit() {
        this.pollData = this.getPollData();
        this.getPollResults();
    }

    getComponentContent() {
        if (!this.component.translations) {
            return "";
        }

        return this.component.translations.find((t) => t.content);
    }

    getBaseUrl() {
        const content = this.getComponentContent();

        if (!content) {
            return null;
        }

        return `files/translations/${content.content}`;
    }

    getPollData() {
        const content = this.getComponentContent();

        try {
            return JSON.parse(content.content);
        } catch (e) {
            return {};
        }
    }

    getPollResults() {
        const poll = this.getPollData();

        if (!poll.id) {
            return;
        }

        const votes = [];

        this.pollService.getVoteResults(poll.id).subscribe((data) => {
            poll.answers.forEach((a) => {
                votes.push({
                    id: a.id,
                    votes: data.votes.find((v) => v.id === a.id).total
                });
            });

            this.myVote = data.my_vote ? data.my_vote : null;
            if (this.myVote) {
                this.selectedOption = data.my_vote;
                this.showingPollResults = true;
            }

            this.optionVotes = votes;
            this.totalVotes = data.total;
        });
    }

    getVoteOptionPercentage(id) {
        const option = this.optionVotes.find((ov) => ov.id === id);
        if (!option || this.totalVotes === 0) {
            return 0;
        }

        const calc = (option.votes / this.totalVotes) * 100;
        return Math.round((calc + Number.EPSILON) * 100) / 100;
    }

    selectVote(voteId) {
        if (this.myVote) {
            return;
        }

        this.selectedOption = this.selectedOption === voteId ? null : voteId;
    }

    togglePollResults() {
        this.selectedOption = null;
        this.showingPollResults = !this.showingPollResults;
    }

    vote() {
        if (!this.selectedOption || this.myVote) {
            return;
        }

        const poll = this.getPollData();

        this.pollService.vote(poll.id, this.selectedOption).subscribe(() => {
            this.getPollResults();
            this.showingPollResults = true;
            this.selectedOption = null;
        });
    }

    makeComponentImageUrl() {
        const token = this.authService.authState.getValue().token;

        const url = this.getBaseUrl();

        if (!url) {
            return "";
        }

        return `${API_URL}/${url}?token=${token}`;
    }

    addOriginalSuffix(url) {
        const parts = url.split(".");
        const extension = parts.slice(-1);

        return parts.slice(0, -1).join(".") + "_original." + extension;
    }

    previewImage() {
        const hqImage = window.btoa(this.addOriginalSuffix(this.getBaseUrl()));

        this.navController.navigateForward([`/image-viewer/${hqImage}`]);
    }

    getComponentTypeById(i) {
        return ["title_lg", "title_sm", "textarea", "image", "pdf", "poll"][
            i - 1
        ];
    }
}
