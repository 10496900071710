<ion-header no-border>
    <ion-toolbar class="ion-padding-horizontal">
        <profile-image></profile-image>
        <ion-title>{{ 'navigation.documents' | translate }}</ion-title>
        <ion-searchbar
            debounce="500"
            placeholder="{{ 'news-search.title' | translate }}"
            routerLink="/documents/search"
        ></ion-searchbar>
        <img
            alt="Zoeken"
            class="search-icon"
            routerLink="/documents/search"
            src="assets/images/icons/search.svg"
        />
    </ion-toolbar>
</ion-header>

<ion-content>
    <div class="wrapper-div">
        <ion-refresher (ionRefresh)="doRefresh($event)" slot="fixed">
            <ion-refresher-content></ion-refresher-content>
        </ion-refresher>
        <h1 class="desktop">{{ 'navigation.documents' | translate }}</h1>
        <div class="desktop-box-wrap">
            <ion-spinner *ngIf="isLoading"></ion-spinner>

            <div class="to-approve" *ngIf="documentsToApprove.length > 0">
                <div class="to-approve-header">
                    <h2>
                        {{ 'documents.to-approve-header-title' | translate }}
                    </h2>
                    <p>
                        {{ 'documents.to-approve-header-content' | translate }}
                    </p>
                </div>
                <ion-list class="to-approve-list" lines="none">
                    <ion-item
                        class="to-approve-list-item"
                        (click)="openLink(document)"
                        *ngFor="let document of documentsToApprove "
                    >
                        <div class="to-approve-list-item-content">
                            <p class="to-approve-list-item-title">
                                {{document.name}}
                            </p>
                            <p class="to-approve-list-item-date">
                                {{ 'documents.changed-on' | translate }}
                                {{document.lastUpdate.startOf('day').format('DD-MM-YYYY')}}
                            </p>
                        </div>
                        <svg
                            width="8"
                            height="12"
                            viewBox="0 0 8 12"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M0.589996 10.59L5.17 6L0.589996 1.41L2 0L8 6L2 12L0.589996 10.59Z"
                                fill="black"
                            />
                        </svg>
                    </ion-item>
                </ion-list>
            </div>

            <div
                *ngIf="documents && documents.length > 0"
                (click)="showSortStateMenu()"
                class="sort-state-menu"
            >
                <span *ngIf="sortByName === true" class="lighter-title">
                    {{ 'documents.name' | translate }}
                </span>
                <span *ngIf="sortLastChanged === true" class="lighter-title">
                    {{ 'documents.last-changed' | translate }}
                </span>
                <span *ngIf="sortLastOpened === true" class="lighter-title">
                    {{ 'documents.last-opened' | translate }}
                </span>

                <svg
                    fill="none"
                    height="5"
                    viewBox="0 0 10 5"
                    width="10"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path d="M0 0L5 5L10 0H0Z" fill="black" opacity="0.3" />
                </svg>
            </div>

            <ion-list class="document-list" lines="none">
                <ion-item
                    (click)="setLastOpenedDate(document); openLink(document)"
                    *ngFor="let document of documents "
                    class="document-list-item"
                    button="true"
                >
                    <div class="document-list-item-svg-holder">
                        <svg-icon
                            *ngIf="document.type === 'map'"
                            src="/assets/icon/folder.svg"
                        ></svg-icon>
                        <svg-icon
                            *ngIf="document.type === 'doc' && document.docType === 'document'"
                            src="/assets/icon/file.svg"
                        ></svg-icon>
                        <svg-icon
                            *ngIf="document.type === 'doc' && document.docType === 'url'"
                            src="/assets/icon/link.svg"
                        ></svg-icon>
                    </div>
                    <div class="document-list-item-content">
                        <span>{{document.name}}</span>
                        <p>
                            {{ 'documents.changed-on' | translate }}
                            {{document.lastUpdate.startOf('day').format('DD-MM-YYYY')}}
                        </p>
                    </div>
                </ion-item>
            </ion-list>
        </div>
        <app-menu-slider #sortStateMenu>
            <div class="sort-state-menu-content">
                <span class="lighter-title">
                    {{ 'documents.sort' | translate }}
                </span>
                <ion-list lines="none">
                    <ion-item
                        (click)="selectSortMethod(1)"
                        [ngClass]="{'is-active': sortByName === true}"
                        button
                    >
                        <ion-label>
                            {{ 'documents.name' | translate }}
                        </ion-label>
                        <svg-icon
                            *ngIf="sortByName === true"
                            src="/assets/icon/check.svg"
                        ></svg-icon>
                    </ion-item>

                    <ion-item
                        (click)="selectSortMethod(2)"
                        [ngClass]="{'is-active': sortLastChanged === true}"
                        button
                    >
                        <ion-label>
                            {{ 'documents.last-changed' | translate }}
                        </ion-label>
                        <svg-icon
                            *ngIf="sortLastChanged === true"
                            src="/assets/icon/check.svg"
                        ></svg-icon>
                    </ion-item>

                    <ion-item
                        (click)="selectSortMethod(3)"
                        [ngClass]="{'is-active': sortLastOpened === true}"
                        button
                    >
                        <ion-label>
                            {{ 'documents.last-opened' | translate }}
                        </ion-label>
                        <svg-icon
                            *ngIf="sortLastOpened === true"
                            src="/assets/icon/check.svg"
                        ></svg-icon>
                    </ion-item>
                </ion-list>
            </div>
        </app-menu-slider>
    </div>
</ion-content>
