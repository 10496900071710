import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BaseHttpService } from "../base-http.service";
import { HelpersModule } from "../../modules/helpers/helpers.module";

@Injectable({
    providedIn: "root"
})
export class NewsService extends BaseHttpService {
    constructor(private http: HttpClient) {
        super();
    }

    fetchNews(page = 1, search = "") {
        const deviceLang = HelpersModule.getDeviceLanguage();
        return this.http.get<any>(
            `${this.baseUrl}/articles?type=article&page=${page}&take=5&search=${search}&deviceLang=${deviceLang}`
        );
    }

    fetchTips() {
        const deviceLang = HelpersModule.getDeviceLanguage();
        return this.http.get<any>(
            `${this.baseUrl}/articles?type=tips&deviceLang=${deviceLang}`
        );
    }

    fetchFavoriteArticles(page = 1) {
        const deviceLang = HelpersModule.getDeviceLanguage();
        return this.http.get<any>(
            `${this.baseUrl}/articles?page=${page}&take=5&deviceLang=${deviceLang}&favorite=true`
        );
    }

    fetchArticle(id, overrideLang = "") {
        let baseUrl = `${this.baseUrl}/articles/${id}`;
        const deviceLang = HelpersModule.getDeviceLanguage();
        baseUrl += `?deviceLang=${deviceLang}`;
        if (overrideLang) {
            baseUrl += `&lang=${overrideLang}`;
        }

        return this.http.get(baseUrl);
    }

    toggleFavorite(articleId: number, isFavorite: boolean) {
        return this.http.post<any>(
            `${this.baseUrl}/articles/${articleId}/favorite`,
            { favorite: isFavorite }
        );
    }
}
