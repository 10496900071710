import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "../../services/auth/auth.service";
import { AuthUser } from "../../entities/AuthUser";

@Component({
    selector: "app-accoding-handbooks",
    templateUrl: "./accoding-handbooks.component.html",
    styleUrls: ["./accoding-handbooks.component.scss"]
})
export class AccodingHandbooksComponent {
    private visitedManualPage = false;
    private profile = new AuthUser(null);

    constructor(
        public router: Router,
        public authService: AuthService
    ) {
        this.authService.userProfileState
            .asObservable()
            .subscribe((authUser) => {
                this.profile = authUser;
                this.visitedManualPage = false;
            });
    }

    showPopup(): boolean {
        if (this.router.isActive("/manuals", false)) {
            // Hide the modal until next login when the user visits the manual page.
            this.visitedManualPage = true;
            return false;
        }

        if (
            (this.router.isActive("news", false) ||
                this.router.isActive("contact-departments", false)) &&
            this.profile.has_manuals_to_accept &&
            !this.visitedManualPage
        ) {
            return true;
        }
    }
}
