<ion-header no-border>
    <ion-toolbar>
        <ion-buttons slot="start">
            <ion-back-button
                class="black"
                defaultHref="/"
                slot="start"
                text=""
            ></ion-back-button>
        </ion-buttons>

        <ion-title>{{ 'navigation.documents' | translate }}</ion-title>
    </ion-toolbar>
    <ion-toolbar>
        <ion-searchbar
            debounce="500"
            placeholder="{{ 'news-search.title' | translate }}"
            routerLink="/documents/search"
        ></ion-searchbar>
    </ion-toolbar>
</ion-header>

<ion-content>
    <div class="wrapper-div">
        <h1 class="desktop">{{ 'navigation.documents' | translate }}</h1>
        <div class="desktop-box-wrap">
            <ion-refresher (ionRefresh)="doRefresh($event)" slot="fixed">
                <ion-refresher-content></ion-refresher-content>
            </ion-refresher>

            <div
                (click)="showSortStateMenu()"
                *ngIf="!noDataInFolder"
                class="sort-state-menu"
            >
                <span *ngIf="sortByName === true" class="lighter-title">
                    {{ 'documents.name' | translate }}
                </span>
                <span *ngIf="sortLastChanged === true" class="lighter-title">
                    {{ 'documents.last-changed' | translate }}
                </span>
                <span *ngIf="sortLastOpened === true" class="lighter-title">
                    {{ 'documents.last-opened' | translate }}
                </span>

                <svg
                    fill="none"
                    height="5"
                    viewBox="0 0 10 5"
                    width="10"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path d="M0 0L5 5L10 0H0Z" fill="black" opacity="0.3" />
                </svg>
            </div>

            <ion-spinner *ngIf="isLoading"></ion-spinner>

            <h5 *ngIf="noDataInFolder" class="no-data-message">
                {{ 'documents.no-documents-found' | translate }}
            </h5>

            <ion-list
                *ngIf="!noDataInFolder"
                class="document-list"
                lines="none"
            >
                <ion-item
                    (click)="setLastOpenedDate(document); openLink(document)"
                    *ngFor="let document of documents "
                    class="document-list-item"
                    button="true"
                >
                    <div class="document-list-item-svg-holder">
                        <svg-icon
                            *ngIf="document.type === 'map'"
                            src="/assets/icon/folder.svg"
                        ></svg-icon>
                        <svg-icon
                            *ngIf="document.type === 'doc' && document.docType === 'document'"
                            src="/assets/icon/file.svg"
                        ></svg-icon>
                        <svg-icon
                            *ngIf="document.type === 'doc' && document.docType === 'url'"
                            src="/assets/icon/link.svg"
                        ></svg-icon>
                    </div>
                    <div class="document-list-item-content">
                        <span>{{document.name}}</span>
                        <p>
                            Gewijzigd op
                            {{document.lastUpdate.startOf('day').format('DD-MM-YYYY')}}
                        </p>
                    </div>
                </ion-item>
            </ion-list>
        </div>
    </div>
    <app-menu-slider #sortStateMenu>
        <div class="sort-state-menu-content">
            <span class="lighter-title">
                {{ 'documents.sort' | translate }}
            </span>
            <ion-list lines="none">
                <ion-item
                    (click)="selectSortMethod(1)"
                    [ngClass]="{'is-active': sortByName === true}"
                    button
                >
                    <ion-label>{{ 'documents.name' | translate }}</ion-label>
                    <svg-icon
                        *ngIf="sortByName === true"
                        src="/assets/icon/check.svg"
                    ></svg-icon>
                </ion-item>

                <ion-item
                    (click)="selectSortMethod(2)"
                    [ngClass]="{'is-active': sortLastChanged === true}"
                    button
                >
                    <ion-label>
                        {{ 'documents.last-changed' | translate }}
                    </ion-label>
                    <svg-icon
                        *ngIf="sortLastChanged === true"
                        src="/assets/icon/check.svg"
                    ></svg-icon>
                </ion-item>

                <ion-item
                    (click)="selectSortMethod(3)"
                    [ngClass]="{'is-active': sortLastOpened === true}"
                    button
                >
                    <ion-label>
                        {{ 'documents.last-opened' | translate }}
                    </ion-label>
                    <svg-icon
                        *ngIf="sortLastOpened === true"
                        src="/assets/icon/check.svg"
                    ></svg-icon>
                </ion-item>
            </ion-list>
        </div>
    </app-menu-slider>
</ion-content>
