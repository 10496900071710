import { Anniversary } from "./Anniversary";

export class TodayAnniversaries {
    date: string;
    items: Array<Anniversary>;
    isNotified: boolean;

    constructor(item) {
        if (!item || !item.date) {
            return;
        }

        this.date = item.date;
        this.items = item.items
            ? item.items.map((i) => new Anniversary(i))
            : [];
        this.isNotified = item.isNotified || false;
    }

    addItems(date, items) {
        if (date === this.date && this.isNotified) {
            return null;
        }

        this.date = date;
        this.items = items.map((i) => new Anniversary(i));
        this.isNotified = false;
    }
}
