import { NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";
import { MasterPage } from "./views/master/master.page";
import { DocumentsPage } from "./views/documents/documents.page";
import { DocumentsDetailPage } from "./views/documents-detail/documents-detail.page";
import { NewsDetailPage } from "./views/news-detail/news-detail.page";
import { AuthorsPage } from "./views/authors/authors.page";
import { ManualListPage } from "./views/manual-list/manual-list.page";
import { ManualPage } from "./views/manual/manual.page";
import { ManualChangesPage } from "./views/manual-changes/manual-changes.page";
import { AuthGuard } from "./guards/Auth/auth-guard.guard";
import { ActiveUserGuard } from "./guards/Auth/active-user.guard";
import { TwoFactorGuard } from "./guards/Auth/two-factor.guard";
import { PushNotificationsPage } from "./views/push-notifications/push-notifications.page";
import { GuestGuard } from "./guards/Auth/guest.guard";
import { FilePreviewPage } from "./views/file-preview/file-preview.page";
import { TermsAndConditionsPage } from "./views/terms-and-conditions/terms-and-conditions.page";
import { PrivacyPolicyPage } from "./views/privacy-policy/privacy-policy.page";
import { FavoritePage } from "./views/news/favorite/favorite.page";
import { WorkClothingPage } from "./views/profile/work-clothing/work-clothing.page";
import { MyServiceSchedulePageModule } from "./views/content-page/content-page.module";
import { PublicPrivacyPolicyPage } from "./views/public/privacy-policy/public-privacy-policy.page";

const routes: Routes = [
    {
        path: "",
        pathMatch: "full",
        redirectTo: "news"
    },
    {
        path: "",
        component: MasterPage,
        canActivate: [AuthGuard],
        children: [
            {
                path: "home",
                redirectTo: "news"
            },
            {
                path: "documents",
                component: DocumentsPage,
                canActivate: [ActiveUserGuard]
            },
            {
                path: "documents/search",
                loadChildren: () =>
                    import(
                        "./views/documents-search/documents-search.module"
                    ).then((m) => m.DocumentsSearchModule),
                canActivate: [ActiveUserGuard]
            },
            {
                path: "documents/:folderId",
                component: DocumentsDetailPage,
                canActivate: [ActiveUserGuard]
            },
            {
                path: "manuals",
                component: ManualListPage,
                canActivate: [ActiveUserGuard]
            },
            {
                path: "manuals/:id",
                component: ManualPage,
                canActivate: [ActiveUserGuard]
            },
            {
                path: "manuals/:id/changes",
                component: ManualChangesPage,
                canActivate: [ActiveUserGuard]
            },
            {
                path: "news",
                loadChildren: () =>
                    import("./views/news/news.module").then(
                        (m) => m.NewsPageModule
                    ),
                canActivate: [ActiveUserGuard]
            },
            {
                path: "news/favorite",
                canActivate: [ActiveUserGuard],
                component: FavoritePage
            },
            {
                path: "news/search",
                loadChildren: () =>
                    import("./views/news-search/news-search.module").then(
                        (m) => m.NewsSearchPageModule
                    ),
                canActivate: [ActiveUserGuard]
            },
            {
                path: "news/:articleId",
                pathMatch: "full",
                component: NewsDetailPage,
                canActivate: [ActiveUserGuard]
            },
            {
                path: "authors/:authorId",
                pathMatch: "full",
                component: AuthorsPage,
                canActivate: [ActiveUserGuard]
            },
            {
                path: "push-notifications",
                component: PushNotificationsPage,
                canActivate: [ActiveUserGuard]
            },
            {
                path: "events",
                loadChildren: () =>
                    import("./views/events/events.module").then(
                        (m) => m.EventsPageModule
                    ),
                canActivate: [ActiveUserGuard]
            },
            {
                path: "events/:id",
                loadChildren: () =>
                    import("./views/event/event.module").then(
                        (m) => m.EventPageModule
                    ),
                canActivate: [ActiveUserGuard]
            },
            {
                path: "furloughs",
                loadChildren: () =>
                    import("./views/furloughs/furloughs/furloughs.module").then(
                        (m) => m.FurloughsPageModule
                    ),
                canActivate: [ActiveUserGuard]
            },
            {
                path: "furloughs/:id/detail",
                loadChildren: () =>
                    import(
                        "./views/furloughs/furloughs-detail/furloughs-detail.module"
                    ).then((m) => m.FurloughsDetailPageModule),
                canActivate: [ActiveUserGuard]
            },
            {
                path: "furloughs-apply",
                loadChildren: () =>
                    import(
                        "./views/furloughs/furloughs-apply/furloughs-apply.module"
                    ).then((m) => m.FurloughsApplyPageModule),
                canActivate: [ActiveUserGuard]
            },
            {
                path: "expenses",
                loadChildren: () =>
                    import("./views/expenses-list/expenses-list.module").then(
                        (m) => m.ExpensesListPageModule
                    ),
                canActivate: [ActiveUserGuard]
            },
            {
                path: "expenses/create",
                loadChildren: () =>
                    import("./views/expenses/expenses.module").then(
                        (m) => m.ExpensesPageModule
                    ),
                canActivate: [ActiveUserGuard]
            },
            {
                path: "contact-departments/:id",
                loadChildren: () =>
                    import(
                        "./views/contact-departments/contact-departments.module"
                    ).then((m) => m.ContactDepartmentsPageModule),
                canActivate: [ActiveUserGuard]
            },

            {
                path: "contact-departments",
                loadChildren: () =>
                    import(
                        "./views/contact-divisions/contact-divisions.module"
                    ).then((m) => m.ContactDivisionsPageModule),
                canActivate: [ActiveUserGuard]
            },

            {
                path: "contact-departments/:departmentId/contacts/:id",
                loadChildren: () =>
                    import("./views/contacts/contacts.module").then(
                        (m) => m.ContactsPageModule
                    ),
                canActivate: [ActiveUserGuard]
            },
            {
                path: "profile",
                loadChildren: () =>
                    import("./views/profile/profile/profile.module").then(
                        (m) => m.ProfilePageModule
                    )
            },
            {
                path: "profile/personal-information",
                loadChildren: () =>
                    import(
                        "./views/profile/personal-information/personal-information.module"
                    ).then((m) => m.PersonalInformationPageModule)
            },
            {
                path: "profile/personal-documents",
                loadChildren: () =>
                    import(
                        "./views/profile/personal-documents/personal-documents.module"
                    ).then((m) => m.PersonalDocumentsPageModule)
            },
            {
                path: "profile/personal-documents/:id",
                loadChildren: () =>
                    import(
                        "./views/profile/personal-documents/personal-document/personal-document.module"
                    ).then((m) => m.PersonalDocumentPageModule)
            },
            {
                path: "profile/work-clothing",
                component: WorkClothingPage
            },
            {
                path: "salaries",
                loadChildren: () =>
                    import("./views/salaries-list/salaries-list.module").then(
                        (m) => m.SalariesListPageModule
                    )
            },
            {
                path: "reports-list",
                loadChildren: () =>
                    import(
                        "./views/reports/reports-list/reports-list.module"
                    ).then((m) => m.ReportsListPageModule)
            },
            {
                path: "image-viewer/:url",
                loadChildren: () =>
                    import("./views/image-viewer/image-viewer.module").then(
                        (m) => m.ImageViewerPageModule
                    ),
                canActivate: [ActiveUserGuard]
            },
            {
                path: "tank-locations-car-washes",
                loadChildren: () =>
                    import(
                        "./views/tank-locations-car-washes/tank-locations-car-washes.module"
                    ).then((m) => m.TankLocationsCarWashesPageModule)
            },
            {
                path: "preview/:type/:id",
                component: FilePreviewPage
            },
            {
                path: "colleagues",
                loadChildren: () =>
                    import("./views/colleagues/colleagues.module").then(
                        (m) => m.ColleaguesPageModule
                    )
            },
            {
                path: "ideas",
                loadChildren: () =>
                    import("./views/ideas/ideas.module").then(
                        (m) => m.IdeasPageModule
                    )
            },
            {
                path: "courses",
                loadChildren: () =>
                    import("./views/courses/courses.module").then(
                        (m) => m.CoursesPageModule
                    )
            },
            {
                path: "locations/:id",
                loadChildren: () =>
                    import("./views/locations/location/location.module").then(
                        (m) => m.LocationPageModule
                    )
            },
            {
                path: "content-page/:id",
                loadChildren: () =>
                    import("./views/content-page/content-page.module").then(
                        (m) => m.MyServiceSchedulePageModule
                    )
            }
        ]
    },
    {
        path: "terms-and-conditions",
        component: TermsAndConditionsPage,
        canActivate: [AuthGuard]
    },
    {
        path: "privacy-policy",
        component: PrivacyPolicyPage,
        canActivate: [AuthGuard]
    },
    {
        path: "public/privacy-policy",
        component: PublicPrivacyPolicyPage
    },
    {
        path: "request-login",
        loadChildren: () =>
            import("./views/auth/request-login/request-login.module").then(
                (m) => m.RequestLoginPageModule
            ),
        canActivate: [GuestGuard]
    },
    {
        path: "sms-login",
        loadChildren: () =>
            import("./views/auth/sms-login/sms-login.module").then(
                (m) => m.SmsLoginPageModule
            ),
        canActivate: [GuestGuard]
    },
    {
        path: "login",
        loadChildren: () =>
            import("./views/auth/login/login.module").then(
                (m) => m.LoginPageModule
            )
    },
    {
        path: "set-pin",
        loadChildren: () =>
            import("./views/auth/set-pin/set-pin.module").then(
                (m) => m.SetPinPageModule
            ),
        canActivate: [TwoFactorGuard]
    },
    {
        path: "confirm-pin",
        loadChildren: () =>
            import("./views/auth/confirm-pin/confirm-pin.module").then(
                (m) => m.ConfirmPinPageModule
            ),
        canActivate: [TwoFactorGuard]
    },
    {
        path: "expenses-list",
        loadChildren: () =>
            import("./views/expenses-list/expenses-list.module").then(
                (m) => m.ExpensesListPageModule
            )
    }
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            preloadingStrategy: PreloadAllModules,
            relativeLinkResolution: "legacy"
        })
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {}
