import { Injectable } from "@angular/core";
import { CanActivate } from "@angular/router";
import { AuthService } from "../../services/auth/auth.service";
import { NavController } from "@ionic/angular";

@Injectable({
    providedIn: "root"
})
export class ActiveUserGuard implements CanActivate {
    constructor(
        public authService: AuthService,
        private navController: NavController
    ) {}

    canActivate(): boolean {
        const isUserActive = this.authService.isUserActive();

        if (isUserActive) {
            return true;
        }

        this.navController.navigateRoot("/profile");
        return false;
    }
}
