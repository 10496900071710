import { API_URL } from "../../environments/environment";

export class Article {
    id: number;
    article_type_id: number;
    title: string;
    profile_title: null;
    profile_description: null;
    profile_user_id: null;
    story_user_id: null;
    profile = null;
    story_profile = null;
    created_at: string;
    updated_at: string;
    translations: any[];
    components: [];
    reading_time: string;
    has_feature_image: boolean;
    published_at: null;
    is_favorite: boolean;

    getFeaturedImagePath() {
        return `${API_URL}/articles/${this.id}/featuredImage`;
    }

    constructor(item) {
        if (item !== null) {
            this.id = item.id;
            this.article_type_id = item.article_type_id;
            this.title = item.title;
            this.profile_title = item.profile_title;
            this.profile_description = item.profile_description;
            this.profile_user_id = item.profile_user_id;
            this.story_user_id = item.story_user_id;
            this.reading_time = item.reading_time;
            this.has_feature_image = item.has_feature_image;
            this.published_at = item.published_at;

            if (!!item.profile) {
                this.profile = item.profile;
            }
            if (!!item.story_profile) {
                this.story_profile = item.story_profile;
            }

            this.created_at = item.created_at;
            this.updated_at = item.updated_at;
            this.translations = item.translations;
            this.components = item.components;
            this.is_favorite = item.is_favorite;
        }
    }
}
