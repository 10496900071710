import { Injectable } from "@angular/core";
import { CanActivate } from "@angular/router";
import { AuthService } from "../../services/auth/auth.service";
import { NavController } from "@ionic/angular";

@Injectable({
    providedIn: "root"
})
export class TwoFactorGuard implements CanActivate {
    constructor(
        public authService: AuthService,
        private navController: NavController
    ) {}

    canActivate(): boolean {
        return this.authService.authState.getValue().hasTwoFactorToken();
    }
}
