import { Injectable } from "@angular/core";
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpErrorResponse
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { Platform } from "@ionic/angular";
import { catchError } from "rxjs/operators";
import { AlertService } from "../services/alert/alert.service";
import { TranslateService } from "@ngx-translate/core";

@Injectable()
export class ServerErrorInterceptor implements HttpInterceptor {
    constructor(
        public platform: Platform,
        private alertService: AlertService,
        private translateService: TranslateService
    ) {}

    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        // if response is status 500 show error message
        return next.handle(request).pipe(
            catchError((error: HttpErrorResponse) => {
                const errorMessages = {
                    500: "errors.server-error",
                    403: "errors.forbidden"
                };

                if (
                    Object.keys(errorMessages).includes(error.status.toString())
                ) {
                    this.alertService.warning(
                        this.translateService.instant(
                            errorMessages[error.status]
                        )
                    );
                }

                return throwError(error);
            })
        );
    }
}
