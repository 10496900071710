import { Injectable } from "@angular/core";
import {
    DefaultSystemBrowserOptions,
    InAppBrowser
} from "@capacitor/inappbrowser";
import { Platform } from "@ionic/angular";

@Injectable({
    providedIn: "root"
})
export class BrowserService {
    constructor(private platform: Platform) {}

    openLink(link: string) {
        if (this.platform.is("cordova")) {
            InAppBrowser.openInSystemBrowser({
                url: link,
                options: DefaultSystemBrowserOptions
            }).catch((error) => {
                console.error(error);
                // Fallback to window.open because some url's are not supported by InAppBrowser (like google maps on android)
                window.open(link, "_blank");
            });
        } else {
            window.open(link, "_blank");
        }
    }
}
