import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";

import { IonicModule } from "@ionic/angular";

import { PrivacyPolicyPage } from "./privacy-policy.page";
import { TranslateModule } from "@ngx-translate/core";

@NgModule({
    imports: [CommonModule, FormsModule, IonicModule, TranslateModule],
    declarations: [PrivacyPolicyPage]
})
export class PrivacyPolicyPageModule {}
