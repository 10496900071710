import { Injectable } from "@angular/core";
import { BaseHttpService } from "../base-http.service";
import { HttpClient } from "@angular/common/http";

@Injectable({
    providedIn: "root"
})
export class EmailsService extends BaseHttpService {
    constructor(private http: HttpClient) {
        super();
    }

    emailAttachment(dossierId: number, email: string, typeUrl: string) {
        return this.http.post(`${this.baseUrl}/${typeUrl}/${dossierId}/email`, {
            email
        });
    }
}
