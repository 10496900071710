import { Injectable } from "@angular/core";
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor
} from "@angular/common/http";
import { from, Observable } from "rxjs";
import { App } from "@capacitor/app";
import { Platform } from "@ionic/angular";

@Injectable()
export class AppendAppVersionInterceptor implements HttpInterceptor {
    constructor(public platform: Platform) {}

    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        if (!this.platform.is("cordova")) {
            const modifiedRequest = request.clone({
                headers: request.headers.set("AB-APP-VERSION", "web")
            });

            return next.handle(modifiedRequest);
        }

        return from(this.handle(request, next));
    }

    async handle(request: HttpRequest<any>, next: HttpHandler) {
        const version = (await App.getInfo()).version;

        const modifiedRequest = request.clone({
            headers: request.headers.set("AB-APP-VERSION", version)
        });

        return next.handle(modifiedRequest).toPromise();
    }
}
