import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BaseHttpService } from "../base-http.service";

@Injectable({
    providedIn: "root"
})
export class TermsService extends BaseHttpService {
    constructor(private http: HttpClient) {
        super();
    }

    acceptTerms() {
        return this.http.get<any>(`${this.baseUrl}/users/me/accept-terms`);
    }

    getTerms() {
        return this.http.get<any>(`${this.baseUrl}/terms/privacy-policy`);
    }
}
