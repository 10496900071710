<ion-header no-border>
    <ion-toolbar>
        <ion-buttons slot="start">
            <ion-back-button defaultHref="/" text=""></ion-back-button>
        </ion-buttons>

        <ion-title>{{ 'news-search.title' | translate }}</ion-title>
    </ion-toolbar>
    <ion-toolbar class="searchbar">
        <ion-searchbar
            #search
            (ionInput)="searchChange()"
            debounce="500"
            placeholder="{{ 'news-search.title' | translate }}"
        ></ion-searchbar>
    </ion-toolbar>
</ion-header>

<ion-content>
    <div class="wrapper-div">
        <h1 class="desktop">{{ 'navigation.documents' | translate }}</h1>
        <div class="desktop-box-wrap">
            <ion-spinner *ngIf="isLoading"></ion-spinner>

            <ion-list class="document-list" lines="none">
                <h4
                    *ngIf="!this.searchDocuments.length && ! isLoading"
                    class="no-results"
                >
                    {{ 'news-search.no-results' | translate }}
                </h4>
                <ion-item
                    *ngFor="let document of searchDocuments"
                    class="document-list-item"
                    (click)="openLink(document)"
                    button="true"
                >
                    <div class="document-list-item-svg-holder">
                        <svg-icon
                            *ngIf="document.type === 'map'"
                            src="/assets/icon/folder.svg"
                        ></svg-icon>
                        <svg-icon
                            *ngIf="document.type === 'doc' && document.docType === 'document'"
                            src="/assets/icon/file.svg"
                        ></svg-icon>
                        <svg-icon
                            *ngIf="document.type === 'doc' && document.docType === 'url'"
                            src="/assets/icon/link.svg"
                        ></svg-icon>
                    </div>
                    <div class="document-list-item-content">
                        <span>{{document.name}}</span>
                        <p>
                            Gewijzigd op
                            {{document.lastUpdate.startOf('day').format('DD-MM-YYYY')}}
                        </p>
                    </div>
                </ion-item>
            </ion-list>
        </div>
    </div>
    <ion-infinite-scroll (ionInfinite)="loadData($event)" threshold="400px">
        <ion-infinite-scroll-content
            loadingSpinner="bubbles"
            loadingText="Haal data op..."
        ></ion-infinite-scroll-content>
    </ion-infinite-scroll>
</ion-content>
