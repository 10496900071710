import { Component, Input } from "@angular/core";
import { API_URL } from "../../../environments/environment";
import { AuthUser } from "../../entities/AuthUser";
import { AuthService } from "../../services/auth/auth.service";
import { NavController } from "@ionic/angular";

@Component({
    selector: "profile-image",
    templateUrl: "./profile-image.component.html",
    styleUrls: ["./profile-image.component.scss"]
})
export class ProfileImageComponent {
    private placeholderImageUrlSvg = "../assets/images/default-account.svg";
    private placeholderImageUrlPng = "../assets/images/default-account.png";
    public imageUrl = this.placeholderImageUrlSvg;
    public profile = null;

    @Input() usePng;

    constructor(
        private authService: AuthService,
        private navController: NavController
    ) {
        this.authService.userProfileState
            .asObservable()
            .subscribe((authUser: AuthUser) => {
                this.profile = authUser;
            });
    }

    ngOnInit() {
        this.fetchImageFromAuthState(this.profile);
    }

    gotoProfile() {
        this.navController.navigateForward([`/profile`]);
    }

    private fetchImageFromAuthState(authUser: AuthUser) {
        const token = this.authService.authState.getValue().token;
        const user = authUser;

        if (user.image_path) {
            this.imageUrl = `${API_URL}/files/users/${user.image_path}?token=${token}&v=${authUser.updatedAtTimestamp()}`;
        } else {
            this.imageUrl = !this.usePng
                ? this.placeholderImageUrlSvg
                : this.placeholderImageUrlPng;
        }
    }
}
